import React, { useEffect, useState } from "react";
import "./Users.scss";
import Search from "../../Components/UI/Search";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUsers } from "../../Redux/userSlice";
import { filterUser } from "../../Helpers/filter";
import Loader from "../../Components/UI/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { courseVariants, containerVariants } from "../../Helpers/animation";
const Users = () => {
	// const [page, setPage] = useState(1);
	const [searchValue, setSearchValue] = useState("");
	const { all: users, status } = useSelector((state) => state.users);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	const searchUsers = (value) => {
		setSearchValue(value);
	};
	return users?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<div className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">All Users</h2>
					<Search handleSearch={searchUsers} text={"users"} />
				</div>
			</header>

			<main className="main">
				<div className="table__container">
					<table className="table">
						<thead className="table__head">
							<tr className="table__row table__head">
								<th className="user__serial__head">S/N</th>
								<th className="user__name__head">Name</th>
								<th className="user__email__head">Email</th>
								<th className="user__completed__head">Completed Courses</th>
								<th className="user__pending__head">Pending Courses</th>
							</tr>
						</thead>
						<motion.tbody
							variants={containerVariants}
							initial="start"
							animate="end"
							exit="exit"
							className="table__body">
							<AnimatePresence exitBeforeEnter>
								{searchValue
									? users
											?.filter((user) => filterUser(user, searchValue))
											.map((user, index) => (
												<motion.tr
													variants={courseVariants}
													className="table__row"
													key={user["_id"]}>
													<td className="serial__number">
														{" "}
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</td>
													<td className="user__name">
														{`${user.firstName} ${user.lastName}`}{" "}
													</td>
													<td className="user__email">{user.email}</td>
													<td className="user__completed">
														{user.courses?.length ?? 0}
													</td>
													<td className="user__pending">
														{user.expert.length +
															user.crash.length +
															user.free.length}
													</td>
												</motion.tr>
											))
									: users?.map((user, index) => (
											<motion.tr
												variants={courseVariants}
												className="table__row"
												key={user["_id"]}>
												<td className="serial__number">
													{" "}
													{(index + 1).toLocaleString("en-US", {
														minimumIntegerDigits: 2,
														useGrouping: false,
													})}
												</td>
												<td className="user__name">
													{`${user.firstName} ${user.lastName}`}{" "}
												</td>
												<td className="user__email">{user.email}</td>
												<td className="user__completed">
													{user.courses?.length ?? 0}
												</td>
												<td className="user__pending">
													{user.expert.length +
														user.crash.length +
														user.free.length}
												</td>
											</motion.tr>
									  ))}
							</AnimatePresence>
						</motion.tbody>
					</table>
				</div>

				{/* 	<div className="pagination__container">
					<div className="pagination">
						<button className="btn pagination__previous pagination__btn ">
							<Arrow /> <span>Previous</span>
						</button>
						<button className="btn pagination__btn "></button>
						<button className="btn pagination__next pagination__btn ">
							<span>Next</span> <Arrow className={"right"} />
						</button>
					</div>
				</div> */}
			</main>
		</div>
	);
};

export default Users;
