import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./Tags.scss";
const Tags = ({ tags, setTags }) => {
	const addTag = (e) => {
		if (
			e.key === "Enter" &&
			!tags.find((tag) => tag === e.target.value.trim())
		) {
			setTags([...tags, e.target.value.trim()]);
			e.target.value = "";
		}
	};
	const removeTag = (index) => {
		setTags(tags.filter((_, tagIndex) => index !== tagIndex));
	};

	return (
		<>
			<AnimatePresence>
				<motion.ul className="tag__container">
					{tags?.length > 0 ? (
						tags?.map((tag, index) => (
							<li className="tag" key={index}>
								<span className="tag__btn" onClick={() => removeTag(index)}>
									x
								</span>
								<span className="tag__content">{tag}</span>
							</li>
						))
					) : (
						<span className="tag__alert">Please add tag</span>
					)}
				</motion.ul>
				<input
					type="text"
					name="tags"
					className="blog__add__tags"
					id="tags"
					placeholder="design, motivation, leadership"
					onKeyUp={addTag}
					onBlur={(e) => {
						if (
							!tags.find((tag) => tag === e.target.value.trim()) &&
							e.target.value.length > 2
						) {
							setTags([...tags, e.target.value.trim()]);
							e.target.value = "";
						}
					}}
				/>
			</AnimatePresence>
		</>
	);
};

export default Tags;
