import React, { useState } from "react";
import "./HonorCode.scss";
import Uploader from "../../Components/Uploader/Uploader";
import { useDispatch } from "react-redux";
import { uploadHonor } from "../../Redux/orientationSlice";
import { useSelector } from "react-redux";
import { BtnLoader } from "../../Components/UI/Loader";
const Honorcode = () => {
	const dispatch = useDispatch();
	const [file, setFile] = useState(null);
	const { status } = useSelector((state) => state?.orientation);
	
	const upload = (file) => {
		setFile(file);
	};

	const handleSubmit = () => {
		dispatch(uploadHonor(file));
	};
	return (
		<section className="container">
			<div className="header">
				<h2 className="heading heading-pri ">Honor Code</h2>
			</div>

			<main className="main">
				<div className="uploader__banner">
					<Uploader fileType={".pdf"} handleUpload={upload}>
						featured pdf
					</Uploader>
				</div>
				<div className="banner__btn">
					<button className="btn btn-pri uploader__btn" onClick={handleSubmit}>
						{status === "loading" ? <BtnLoader /> : "Save"}
					</button>
				</div>
			</main>
		</section>
	);
};

export default Honorcode;
