import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import Search from "../../Components/UI/Search";
import { DeleteBtn } from "../../Components/UI/Buttons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMessages, replyMessage } from "../../Redux/supportSlice";
import { filterItems } from "../../Helpers/filter";
import { AnimatePresence } from "framer-motion";
import { containerVariants, supportVariants } from "../../Helpers/animation";
import { motion } from "framer-motion";
import { BtnLoader } from "../../Components/UI/Loader";
import { useRef } from "react";
const ContactUs = () => {
	const { all: messages, status } = useSelector((state) => state.support);
	const [searchValue, setSearchValue] = useState(null);
	const [reply, setReply] = useState(null);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getMessages());
	}, [dispatch]);

	const [selectedContent, setSelectedContent] = useState(null);
	const showContent = (el) => {
		setSelectedContent(el);
		setReply({ ...reply, messageId: String(el["_id"]) });
	};
	const searchMessages = (value) => {
		setSearchValue(value);
	};
	const handleChange = (e) => {
		setReply({ ...reply, message: String(e.target.value).trim() });
	};
	const replyRef = useRef(null);
	const handleSubmit = () => {
		replyRef.current.value = "";
		dispatch(replyMessage(reply));
	};

	const timeDifference = (date2) => {
		const date1 = new Date();
		date2 = new Date(date2);
		var difference = date1.getTime() - date2.getTime();

		var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
		difference -= daysDifference * 1000 * 60 * 60 * 24;
		/* 
		var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
		difference -= hoursDifference * 1000 * 60 * 60;

		var minutesDifference = Math.floor(difference / 1000 / 60);
		difference -= minutesDifference * 1000 * 60;

		var secondsDifference = Math.floor(difference / 1000);
 */
		return daysDifference;
	};
	useEffect(() => {
		messages && setSelectedContent(messages[0]);
	}, [messages]);
	return (
		<section className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">contact us</h2>
				</div>
			</header>
			<main className="main mail__main">
				<div className="mail__container banner">
					<aside className="mail__overview">
						<div className="mail__overview__header">
							<Search handleSearch={searchMessages}>messages</Search>
							<div className="mail__overview__category">
								<span>All</span>
							</div>
						</div>
						<motion.div
							variants={containerVariants}
							initial="start"
							animate="end"
							exit="exit"
							className="mail__overview__body">
							<AnimatePresence exitBeforeEnter>
								{searchValue
									? messages
											.filter((message) =>
												filterItems(
													message?.sender?.firstName,
													message?.sender.lastName,
													message?.message,
													searchValue
												)
											)
											?.map((message, index) => (
												<motion.div
													variants={supportVariants}
													className="message"
													key={message["_id"]}
													onClick={() => showContent(message)}>
													<div className="message__header">
														<h3 className="message__author mail__author">
															{message?.sender?.firstName}&nbsp;
															{message?.sender?.lastName}
														</h3>
														<div className="message__tag">
															{message?.replies?.length < 1 ? (
																<button className="btn btn-pri message__tag__btn">
																	NEW
																</button>
															) : (
																<span>
																	{timeDifference(message?.createdAt)} days ago
																</span>
															)}
														</div>
													</div>
													<p className="message__body">
														{message?.message?.length > 80
															? message?.message?.substr(0, 80) + "..."
															: message?.message}
													</p>
												</motion.div>
											))
									: messages?.map((message, index) => (
											<motion.div
												variants={supportVariants}
												className="message"
												key={message["_id"]}
												onClick={() => showContent(message)}>
												<div className="message__header">
													<h3 className="message__author mail__author">
														{message?.sender?.firstName}&nbsp;
														{message?.sender?.lastName}
													</h3>
													<div className="message__tag">
														{message?.replies?.length < 1 ? (
															<button className="btn btn-pri message__tag__btn">
																NEW
															</button>
														) : (
															<span>
																{timeDifference(message?.createdAt) > 1
																	? timeDifference(message?.createdAt) +
																	  " days ago"
																	: timeDifference(message?.createdAt) +
																	  " day ago"}
															</span>
														)}
													</div>
												</div>
												<p className="message__body">
													{message?.message?.length > 80
														? message?.message?.substr(0, 80) + "..."
														: message?.message}
												</p>
											</motion.div>
									  ))}
							</AnimatePresence>
						</motion.div>
					</aside>
					<section className="mail__content">
						<div className="mail__details">
							<div className="mail__header">
								<div className="mail__header__info">
									<h4 className="mail__author heading">
										{selectedContent?.sender.firstName}&nbsp;
										{selectedContent?.sender.lastName}
									</h4>
									<h4 className="mail__author__email email">
										{selectedContent?.sender?.email}
									</h4>
								</div>
								<div className="mail__header__action">
									<DeleteBtn text={"delete"} />
								</div>
							</div>
							<div className="mail__body">{selectedContent?.message}</div>
						</div>
						<div className="mail__reply">
							<div className="mail__reply__header">
								<h3 className="heading mail__reply__heading">Reply</h3>
							</div>
							<textarea
								name="reply__message"
								id="reply__message"
								placeholder="write something here..."
								className="mail__reply__message"
								ref={replyRef}
								onChange={handleChange}></textarea>
							<div className="mail__reply__action">
								<button className="btn btn-cancel mr-2" onClick={""}>
									Cancel
								</button>
								<button className="btn btn-pri btn-load" onClick={handleSubmit}>
									{status === "loading" ? <BtnLoader /> : "Send"}
								</button>
							</div>
						</div>
					</section>
				</div>
			</main>
		</section>
	);
};

export default ContactUs;
