import React, { useEffect, useState } from "react";
import "./Courses.scss";

import { EditBtn } from "../../Components/UI/Buttons";
import Select from "../../Components/UI/Select";
import Toggle from "../../Components/UI/Toggle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCourses, setStatus } from "../../Redux/courseSlice";
import { motion, AnimatePresence } from "framer-motion";


import { imageFallback } from "../../Helpers/others";
import Editcourse from "./EditCourse";
import { courseVariants, containerVariants } from "../../Helpers/animation";
import Loader from "../../Components/UI/Loader";
import Addcategory from "./AddCategory";
import Category from "./Category";
const Courses = () => {
	const { all: courses, status } = useSelector((state) => state.courses);
	const { all: instructors } = useSelector((state) => state.instructors);
	const dispatch = useDispatch();
	const [add, setAdd] = useState("");
	useEffect(() => {
		dispatch(getCourses());
	}, [dispatch]);
	const [edit, setEdit] = useState("");

	const changeStatus = ({ status, id }) => {
		dispatch(setStatus({ status, id }));
	};
	const sortCourses = (sort) => {
		dispatch(getCourses(sort));
	};

	const closeModal = () => {
		setEdit("");
		setAdd("");
	};

	const options = [
		{ type: "free lesson", sort: "free" },
		{ type: "crash course", sort: "crash" },
		{ type: "expert led course", sort: "expert" },
	];

	const openAddModal = () => {
		setAdd(true);
	};
	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);

	return courses?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<>
			<AnimatePresence>
				{add && <Addcategory closeModal={closeModal} />}
			</AnimatePresence>

			<AnimatePresence>
				{edit && (
					<Editcourse
						course={edit}
						key={"edit_course_modal"}
						closeModal={closeModal}
					/>
				)}
				<section className="container ">
					<div className="header">
						<div className="header__content">
							<h2 className="heading heading-pri">All Courses</h2>
						</div>

						<div className="header__content">
							<div className="options">
								<Select options={options} handleChange={sortCourses} />
							</div>
						</div>
					</div>
				
					<div className="course__categories">
						<Category openAddModal={openAddModal} />
					</div>
					

					<motion.main
						variants={containerVariants}
						initial="start"
						animate="end"
						exit="exit"
						className="main course__container grid">
						<AnimatePresence>
							{courses?.map((course) => {
								const author = instructors?.find(
									(instructor) => instructor["_id"] === course.author
								);
								return (
									<motion.div
										variants={courseVariants}
										className="course"
										key={course["_id"]}>
										<div className="course__content course__content-1">
											<div className="course__content__left">
												<div className="course__image">
													<img
														src={course?.featuredImage}
														alt="course"
														className="course__img"
														onError={imageFallback}
													/>
												</div>
												<h3 className="course__title heading">
													{course.title}
												</h3>
												{course?.weeks ? (
													<div className="course__type subtle subtle-expert">
														expert led course
													</div>
												) : course?.modules ? (
													<div className="course__type subtle subtle-crash">
														crash course
													</div>
												) : (
													<div className="course__type subtle subtle-free">
														free lesson
													</div>
												)}
											</div>
											<div className="course__content__right">
												<div className="course__toggle">
													<Toggle
														value={course.isActive}
														handleChange={changeStatus}
														id={course["_id"]}
													/>
												</div>
												<h2 className="course__price">
													&#8358;{course?.price}
												</h2>
											</div>
										</div>
										<div className="course__content course__content-2">
											<div className="course__author">
												<div className="course__author__image">
													<img
														src={author?.profileImg}
														alt="author"
														className="course__img"
														onError={imageFallback}
													/>
												</div>
												<h3 className="course__author__name heading">
													{author?.firstName} {author?.lastName}
												</h3>
											</div>
											<div className="course__edit">
												<EditBtn handleClick={() => setEdit(course)} />
											</div>
										</div>
									</motion.div>
								);
							})}
						</AnimatePresence>
					</motion.main>
				</section>
			</AnimatePresence>
		</>
	);
};

export default Courses;
