import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader, { getFileHeader } from "../Helpers/header";
// import header from "../Helpers/header";
import { handleErrors, handleSuccess } from "../Helpers/others";

export const getSubscribers = createAsyncThunk(
	"subscribers/getSubscribers",
	async (_, { dispatch }) => {
		return axios
			.get(`${config.baseUrl}/admin/subscriber/all`, {
				headers: getHeader(),
			})
			.then((res) => res.data)
			.then(({ data }) => data)
			.catch((err) =>
				handleErrors({ err, msg: `failed to load subscribers` }, dispatch)
			);
	}
);
export const mailSubscribers = createAsyncThunk(
	"subscribers/mailSubscribers",
	async (details, { dispatch }) => {
		const formData = new FormData();
		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		return axios
			.post(`${config.baseUrl}/admin/subscriber/mail`, formData, {
				headers: getFileHeader(),
			})
			.then((res) => {
				handleSuccess("successfully sent mail", dispatch);
				return res.data;
			})
			.then(({ data }) => data)

			.catch((err) =>
				handleErrors({ err, msg: `failed to send mail` }, dispatch)
			);
	}
);

export const deleteSubscriber = createAsyncThunk(
	"subscribers/deleteSubscriber",

	async (id, { dispatch }) => {
		return axios
			.delete(`${config.baseUrl}/admin/subscriber/remove/${id}`, {
				headers: getHeader(),
			})
			.then((res) => {
				handleSuccess("successfully deleted subscriber", dispatch);
				return res.data;
			})
			.then(dispatch(getSubscribers()))

			.catch((err) =>
				handleErrors({ err, msg: `failed to delete subscriber` }, dispatch)
			);
	}
);

export const subscriberSlice = createSlice({
	name: "subscribers",
	initialState: { all: [], status: null },

	extraReducers: {
		[getSubscribers.pending]: (state, action) => {
			state.status = "loading";
		},
		[getSubscribers.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getSubscribers.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deleteSubscriber.pending]: (state, action) => {
			state.status = "loading";
		},
		[deleteSubscriber.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deleteSubscriber.rejected]: (state, action) => {
			state.status = "failed";
		},
		[mailSubscribers.pending]: (state, action) => {
			state.status = "loading";
		},
		[mailSubscribers.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[mailSubscribers.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default subscriberSlice.reducer;
