import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import { getFileHeader } from "../Helpers/header";
import { handleErrors, handleSuccess } from "../Helpers/others";

export const getPosts = createAsyncThunk(
	"blog/getPosts",

	async (sort, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		if (sort) {
			return axios
				.get(`${config.baseUrl}/admin/blog/recents`, options)
				.then((res) => {
					return res.data.data;
				})

				.catch((err) =>
					handleErrors(
						{
							err,
							msg: `failed to load blog posts	`,
						},
						dispatch
					)
				);
		} else {
			return axios
				.get(`${config.baseUrl}/admin/blog/all`, options)
				.then((res) => {
					return res.data.data;
				})

				.catch((err) =>
					handleErrors(
						{
							err,
							msg: `failed to load blog posts	`,
						},
						dispatch
					)
				);
		}
	}
);

export const deletePost = createAsyncThunk(
	"blog/deletePost",

	async (id, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		return axios
			.delete(`${config.baseUrl}/admin/blog/${id}`, options)
			.then((res) => {
				handleSuccess("successfully deleted blog post", dispatch);
				dispatch(getPosts());
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to delete blog post	`,
					},
					dispatch
				)
			);
	}
);

export const getComments = createAsyncThunk(
	"blog/getComments",

	async (id, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		return axios
			.get(`${config.baseUrl}/admin/blog/comments`, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to load comments	`,
					},
					dispatch
				)
			);
	}
);
export const deleteComment = createAsyncThunk(
	"blog/deleteComment",

	async ({ blogId, id }, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		return axios
			.delete(`${config.baseUrl}/admin/blog/${blogId}/comment/${id}`, options)
			.then((res) => {
				handleSuccess("successfully deleted comment", dispatch);
				dispatch(getComments());
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to delete comment	`,
					},
					dispatch
				)
			);
	}
);
export const getSinglePost = createAsyncThunk(
	"blog/getSinglePost",

	async (id, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		return axios
			.get(`${config.baseUrl}/admin/blog/${id}`, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to load blog post	`,
					},
					dispatch
				)
			);
	}
);

export const addPost = createAsyncThunk(
	"blog/addPost",

	async (details, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		const formData = new FormData();

		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		return axios
			.post(`${config.baseUrl}/admin/blog/new`, formData, options)
			.then((res) => {
				handleSuccess("successfully created post", dispatch);
				return res.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to create post`,
					},
					dispatch
				)
			);
	}
);

export const updatePost = createAsyncThunk(
	"blog/updatePost",

	async ({ details, id }, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};
		const formData = new FormData();

		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		return axios
			.patch(`${config.baseUrl}/admin/blog/${id}`, formData, options)
			.then((res) => {
				dispatch(getPosts());
				handleSuccess("successfully updated post", dispatch);
				return res.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to update post	`,
					},
					dispatch
				)
			);
	}
);

export const blogSlice = createSlice({
	name: "blog",
	initialState: { all: [], status: null, current: {}, comments: [] },

	extraReducers: {
		[getPosts.pending]: (state, action) => {
			state.status = "loading";
		},
		[getPosts.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
			state.current = {};
		},
		[getPosts.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deletePost.pending]: (state, action) => {
			state.status = "loading";
		},
		[deletePost.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deletePost.rejected]: (state, action) => {
			state.status = "failed";
		},
		[addPost.pending]: (state, action) => {
			state.status = "loading";
		},
		[addPost.fulfilled]: (state, { payload }) => {
			state.status = "success";

			state.current = payload;
		},
		[addPost.rejected]: (state, action) => {
			state.status = "failed";
		},
		[getSinglePost.pending]: (state, action) => {
			state.status = "loading";
		},
		[getSinglePost.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.current = payload;
		},
		[getSinglePost.rejected]: (state, action) => {
			state.status = "failed";
		},
		[updatePost.pending]: (state, action) => {
			state.status = "loading";
		},
		[updatePost.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.current = payload;
		},
		[updatePost.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deletePost.pending]: (state, action) => {
			state.status = "loading";
		},
		[deletePost.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deletePost.rejected]: (state, action) => {
			state.status = "failed";
		},
		[getComments.pending]: (state, action) => {
			state.status = "loading";
		},
		[getComments.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.comments = payload;
		},
		[getComments.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deleteComment.pending]: (state, action) => {
			state.status = "loading";
		},
		[deleteComment.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deleteComment.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default blogSlice.reducer;
