import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Btn } from "../../Components/UI/Buttons";
import { setPrice } from "../../Redux/courseSlice";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";
import { useSelector } from "react-redux";
const Editcourse = ({ course, closeModal }) => {
	const [details, setDetails] = useState({
		courseId: course["_id"],
		price: course["price"],
	});
	const { status } = useSelector((state) => state.courses);
	const dispatch = useDispatch();
	const handleChange = (e) => {
		setDetails({ ...details, [e.target.name]: Number(e.target.value.trim()) });
	};

	const updateCourse = () => {
		if (!details) {
			return;
		}
		dispatch(setPrice(details));
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal course__modal">
				<h2 className="heading-sec modal__heading heading"> Edit price</h2>
				<div className="modal__content">
					<div className="modal__input__group">
						<label htmlFor="price">Price</label>
						<input
							type="number"
							name="price"
							id="price"
							placeholder="&#8358;"
							defaultValue={course?.price}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="modal__footer">
					<button
						className="btn btn-cancel"
						onClick={(e) => {
							closeModal();
						}}>
						Cancel
					</button>
					<Btn handleClick={updateCourse} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default Editcourse;
