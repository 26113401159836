import React, { useEffect, useState } from "react";
import "./Coupons.scss";
import Search from "../../Components/UI/Search";
import { EditBtn, DeleteBtn } from "../../Components/UI/Buttons";
import Toggle from "../../Components/UI/Toggle";
import { AddBtn } from "../../Components/UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCoupon,
	disableCoupon,
	enableCoupon,
	getCoupons,
} from "../../Redux/couponSlice";
import { filterItems } from "../../Helpers/filter";
import AddCouponModal from "./AddCouponModal";
import EditCouponModal from "./EditCouponModal";
import Loader from "../../Components/UI/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants, courseVariants } from "../../Helpers/animation";

const Coupons = () => {
	const [searchValue, setSearchValue] = useState("");
	const dispatch = useDispatch();
	const { all: coupons, status } = useSelector((state) => state.coupons);

	const [addModal, showAddModal] = useState(false);
	const [editModal, showEditModal] = useState(false);
	useEffect(() => {
		dispatch(getCoupons());
	}, [dispatch]);
	const searchCoupons = (value) => {
		setSearchValue(value);
	};
	const setStatus = ({ status, id }) => {
		if (status) {
			dispatch(enableCoupon(id));
		} else {
			dispatch(disableCoupon(id));
		}
	};

	const removeCoupon = (id) => {
		dispatch(deleteCoupon(id));
	};
	const openAddModal = () => {
		showAddModal(!addModal);
	};

	const openEditModal = (coupon) => {
		showEditModal(coupon);
	};

	const closeModal = () => {
		showAddModal(false);
		showEditModal(false);
	};

	useEffect(() => {
		if (String(status) === "success") {
			closeModal();
		}
	}, [status]);
	return coupons?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<div class Name="container">
			<AnimatePresence exitBeforeEnter>
				{addModal && <AddCouponModal closeModal={closeModal} />}
				{editModal && (
					<EditCouponModal closeModal={closeModal} coupon={editModal} />
				)}
			</AnimatePresence>
			<header className="header">
				<div className="header">
					<div className="header__content">
						<h2 className="heading-pri mr-2">All Coupons</h2>
						<AddBtn handleClick={openAddModal} />
					</div>
					<Search handleSearch={searchCoupons}>coupons</Search>
				</div>
			</header>

			<main className="main">
				<div className="table__container">
					<table className="table">
						<thead className="table__head">
							<tr className="table__row">
								<th className="coupon__serial__head">S/N</th>
								<th className="coupon__name__head">Name</th>
								<th className="coupon__code__head">Coupon code</th>
								<th className="coupon__percentage__head">percentage </th>
								<th className="coupon__status__head">status</th>
								<th className="coupon__status__head">action</th>
							</tr>
						</thead>
						<motion.tbody
							variants={containerVariants}
							initial="start"
							animate="end"
							exit="exit"
							className="table__body">
							<AnimatePresence exitBeforeEnter>
								{searchValue
									? coupons
											?.filter((coupon) =>
												filterItems(
													coupon?.name,
													coupon?.code,
													coupon?.discount,
													searchValue
												)
											)
											?.map((coupon, index) => (
												<motion.tr
													variants={courseVariants}
													className="table__row coupon"
													key={coupon["_id"]}>
													<td className="serial__number">
														{index.toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</td>
													<td className="coupon__name">
														{coupon?.name ?? coupon.code}
													</td>
													<td className="coupon__code">{coupon.code}</td>
													<td className="coupon__percentage">
														{coupon.discount}
													</td>
													<td className="coupon__status">
														<Toggle
															id={coupon["_id"]}
															value={coupon.isActive}
															handleChange={setStatus}
														/>
													</td>
													<td className="coupon__action">
														<EditBtn
															handleClick={() => openEditModal(coupon)}
														/>
														<DeleteBtn
															handleClick={() => removeCoupon(coupon["_id"])}
														/>
													</td>
												</motion.tr>
											))
									: coupons?.map((coupon, index) => (
											<motion.tr
												variants={courseVariants}
												className="table__row coupon"
												key={coupon["_id"]}>
												<td className="serial__number">
													{(index + 1).toLocaleString("en-US", {
														minimumIntegerDigits: 2,
														useGrouping: false,
													})}
												</td>
												<td className="coupon__name">
													{coupon?.name ?? coupon.code}
												</td>
												<td className="coupon__code">{coupon.code}</td>
												<td className="coupon__percentage">
													{coupon.discount}
												</td>
												<td className="coupon__status">
													<Toggle
														id={coupon["_id"]}
														value={coupon.isActive}
														handleChange={setStatus}
													/>
												</td>
												<td className="coupon__action">
													<EditBtn handleClick={() => openEditModal(coupon)} />
													<DeleteBtn
														handleClick={() => {
															removeCoupon(coupon["_id"]);
														}}
													/>
												</td>
											</motion.tr>
									  ))}
							</AnimatePresence>
						</motion.tbody>
					</table>
				</div>
			</main>
		</div>
	);
};

export default Coupons;
