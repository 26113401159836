import "./Styles/App.scss";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router";
import Users from "./Routes/Users/Users";
import Courses from "./Routes/Courses/Courses";
import Instructors from "./Routes/Instructors/Instructors";
import Coupons from "./Routes/Coupons/Coupons";
import Landing from "./Routes/Landing/Landing";
import Testimonials from "./Routes/Testimonial/Testimonials";
import Subscribers from "./Routes/Subscribers/Subscribers";
import TutorRequest from "./Routes/TutorRequest/TutorRequest";
import Organization from "./Routes/Organization/Organization";
import Honorcode from "./Routes/HonorCode/HonorCode";
import Orientation from "./Routes/Orientation/Orientation";
import ContactUs from "./Routes/ContactUs/ContactUs";
import Blog from "./Routes/Blog/Blog";
import AddPost from "./Routes/Blog/AddPost";
import EditPost from "./Routes/Blog/EditPost";
import Comments from "./Routes/Blog/Comments";
import { AuthWrapper } from "./Routes/SignIn/Auth";
import Dashboard, { DashboardWrapper } from "./Components/Dashboard";
import SignOut from "./Routes/SignIn/SignOut";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "./Redux/authSlice";
import Auth from "./Routes/SignIn/Auth";
import PasswordReset from "./Routes/SignIn/PasswordReset";
import SignIn from "./Routes/SignIn/SignIn";
import Loader from "./Components/UI/Loader";
import { useSelector } from "react-redux";
import { alertClear } from "./Redux/alertSlice";
import Cohort from "./Routes/Cohort/Cohort";
import AutoEnroll from "./Routes/AutoEnroll";
function App() {
	const [loading, setLoading] = useState(true);
	const { status } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	useEffect(() => {
		const token = localStorage.getItem("admin");
		if (token) {
			dispatch(getProfile());
		}
	}, [dispatch]);

	useEffect(() => {
		if (status !== "loading") {
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}
	}, [dispatch, status]);
	const location = useLocation();
	useEffect(() => {
		dispatch(alertClear());
	}, [location, dispatch]);
	return (
		<div className="app">
			{loading && <Loader />}

			<Routes location={location} key={location.key}>
				<Route
					path={"/dashboard"}
					element={
						<DashboardWrapper>
							<Dashboard />
						</DashboardWrapper>
					}>
					<Route exact path="/dashboard/cohort" element={<Cohort />} />
					<Route exact path="/dashboard/users" element={<Users />} />
					<Route exact path="/dashboard/courses" element={<Courses />} />
					<Route
						exact
						path="/dashboard/instructors"
						element={<Instructors />}
					/>
					<Route exact path="/dashboard/auto-enroll" element={<AutoEnroll />} />
					<Route exact path="/dashboard/coupons" element={<Coupons />} />
					<Route exact path="/dashboard/landing-page" element={<Landing />} />
					<Route
						exact
						path="/dashboard/testimonials"
						element={<Testimonials />}
					/>
					<Route
						exact
						path="/dashboard/subscribers"
						element={<Subscribers />}
					/>
					<Route
						exact
						path="/dashboard/tutor-request"
						element={<TutorRequest />}
					/>
					<Route
						exact
						path="/dashboard/organization"
						element={<Organization />}
					/>
					<Route exact path="/dashboard/honor-code" element={<Honorcode />} />
					<Route
						exact
						path="/dashboard/orientation"
						element={<Orientation />}
					/>
					<Route exact path="/dashboard/contact-us" element={<ContactUs />} />
					<Route path="/dashboard/blog/" element={<Outlet />}>
						<Route exact path="/dashboard/blog/add" element={<AddPost />} />
						<Route
							exact
							path="/dashboard/blog/edit/:id"
							element={<EditPost />}
						/>
						<Route
							exact
							path="/dashboard/blog/comments/:id"
							element={<Comments />}
						/>
						<Route path="/dashboard/blog/" element={<Blog />} />
					</Route>
					<Route
						exact
						path="/dashboard"
						element={<Navigate to={"/dashboard/courses"} replace />}
					/>
				</Route>
				<Route path={"/signout"} element={<SignOut />}></Route>

				<Route
					path="/auth"
					element={
						<AuthWrapper>
							<Auth />
						</AuthWrapper>
					}>
					<Route
						exact
						path="/auth/password_recovery"
						element={<PasswordReset />}
					/>
					<Route path="/auth/signin" element={<SignIn />} />
				</Route>

				<Route path="*" element={<Navigate to={"/dashboard"} />} />
			</Routes>
		</div>
	);
}

export default App;
