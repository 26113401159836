import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader from "../Helpers/header";
import { handleErrors as handleFail, handleSuccess } from "../Helpers/others";

const handleErrors = (err, dispatch) => {
	if (err.response) {
		// Request made and server responded
		/* console.log(err.response.data);
		console.log(err.response.status);
		console.log(err.response.headers);
		 */ dispatch(authError(err.response.data));
	} else {
		// Something happened in setting up the request that triggered an Error
		dispatch(authError(err.message));
	}
};

export const getProfile = createAsyncThunk(
	"auth/getProfile",

	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/profile`, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleFail({ err, msg: `failed to load profile` }, dispatch)
			);
	}
);

export const getOtp = createAsyncThunk(
	"auth/getOtp",

	async (email, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.post(`${config.baseUrl}/admin/password_recovery`, email, options)
			.then(({ data }) => {
				return { otp: data.data.otp, email: email?.email };
			})
			.catch((err) => handleErrors(err, dispatch));
	}
);

export const updatePassword = createAsyncThunk(
	"auth/updatePassword",

	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.post(`${config.baseUrl}/admin/password_reset`, details, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleFail({ err, msg: `failed to reset password` }, dispatch)
			);
	}
);

export const editProfile = createAsyncThunk(
	"auth/editProfile",

	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(`${config.baseUrl}/admin/update_profile`, details, options)
			.then((res) => {
				handleSuccess("successfully updated profile", dispatch);
				return res.data.data;
			})
			.catch((err) =>
				handleFail({ err, msg: `failed to update profile` }, dispatch)
			);
	}
);

export const adminSignin = createAsyncThunk(
	"auth/adminSignin",

	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};
		return axios
			.post(`${config.baseUrl}/admin/signin`, details, options)
			.then((res) => {
				localStorage.setItem("admin", res?.data?.token);
				return res;
			})
			.then((res) => {
				delete res.data.token;
				return res.data;
			})
			.catch((err) => handleErrors(err, dispatch));
	}
);

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		status: null,
		otp: null,
		passwordChanged: null,
		error: null,
	},
	reducers: {
		adminSignOut: (state, action) => {
			localStorage.removeItem("admin");

			return {
				user: {},
			};
		},
		authError: (state, action) => {
			return {
				error: action.payload,
			};
		},
		authErrorClear: (state, action) => {
			return {
				error: "",
			};
		},
	},
	extraReducers: {
		[getProfile.pending]: (state, action) => {
			state.status = "loading";
		},
		[getProfile.fulfilled]: (state, { payload }) => {
			state.user = payload;
			state.status = "success";
		},
		[getProfile.rejected]: (state, action) => {
			state.status = "failed";
		},
		[adminSignin.pending]: (state, action) => {
			state.status = "loading";
		},
		[adminSignin.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.user = payload;
		},
		[adminSignin.rejected]: (state, action) => {
			state.status = "failed";
		},
		[getOtp.pending]: (state, action) => {
			state.status = "loading";
		},
		[getOtp.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.otp = payload;
		},
		[getOtp.rejected]: (state, action) => {
			state.status = "failed";
		},
		[updatePassword.pending]: (state, action) => {
			state.status = "loading";
		},
		[updatePassword.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.passwordChanged = payload;
		},
		[updatePassword.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export const { adminSignOut, authError, authErrorClear } = authSlice.actions;
export default authSlice.reducer;
