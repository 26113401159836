import React, { useEffect } from "react";
import "./Organization.scss";
import { useSelector, useDispatch } from "react-redux";
import { getBooking } from "../../Redux/organizationSlice";
import Loader from "../../Components/UI/Loader";
import { containerVariants, courseVariants } from "../../Helpers/animation";
import { motion } from "framer-motion";
const Organization = () => {
	const dispatch = useDispatch();
	const { all: bookings, status } = useSelector((state) => state.organization);
	useEffect(() => {
		dispatch(getBooking());
	}, [dispatch]);
	return bookings?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<div className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">All tutors</h2>
					<div className="header__content"></div>
				</div>
			</header>

			<main className="main organization">
				<div className="table__container">
					<table className="table">
						<thead className="table__head">
							<tr className="table__row">
								<th className="person__name__head">Name</th>
								<th className="person__email__head">Email</th>
								<th className="person__completed__phone">Phone Number</th>
								<th className="person__pending__course">Company name</th>
								<th className="person__pending__course">Category</th>
							</tr>
						</thead>
						<motion.tbody
							variants={containerVariants}
							initial="start"
							animate="end"
							className="table__body">
							{bookings?.map((booking, index) => (
								<motion.tr variants={courseVariants} className="table__row">
									<td className="person__name tutor__name">{booking?.name} </td>
									<td className="person__email tutor__email">
										{booking?.email}
									</td>
									<td className="person__phone">{booking?.number}</td>
									<td className="person__course ">{booking?.company}</td>
									<td className="person__category">{"company"}</td>
								</motion.tr>
							))}
						</motion.tbody>
					</table>
				</div>

				{/* 	<div className="pagination__container">
					<div className="pagination">
						<button className="btn pagination__previous pagination__btn ">
							<Arrow /> <span>Previous</span>
						</button>
						<button className="btn pagination__btn "></button>
						<button className="btn pagination__next pagination__btn ">
							<span>Next</span> <Arrow className={"right"} />
						</button>
					</div>
				</div> */}
			</main>
		</div>
	);
};

export default Organization;
