import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader from "../Helpers/header";
import { handleSuccess, handleErrors } from "../Helpers/others";

export const getCoupons = createAsyncThunk(
	"coupons/getCoupons",
	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/coupon/all`, options)
			.then((res) => res.data)
			.then(({ data }) => {
				return data.coupons;
			})
			.catch((err) =>
				handleErrors({ err, msg: `failed to load coupons` }, dispatch)
			);
	}
);

export const createCoupon = createAsyncThunk(
	"coupons/createCoupon",
	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.post(`${config.baseUrl}/admin/coupon/create`, details, options)
			.then((res) => {
				handleSuccess("successfully created coupon", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCoupons()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to create coupon` }, dispatch)
			);
	}
);

export const updateCoupon = createAsyncThunk(
	"coupons/updateCoupon",
	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(`${config.baseUrl}/admin/coupon/update`, details, options)
			.then((res) => {
				handleSuccess("successfully updated coupon", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCoupons()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to update coupon` }, dispatch)
			);
	}
);

export const deleteCoupon = createAsyncThunk(
	"coupons/deleteCoupon",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};
		return axios
			.delete(`${config.baseUrl}/admin/coupon/delete`, {
				headers: options.headers,
				data: { id },
			})
			.then((res) => {
				handleSuccess("successfully deleted coupon", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCoupons()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to delete coupon` }, dispatch)
			);
	}
);

export const enableCoupon = createAsyncThunk(
	"coupons/enableCoupon",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(`${config.baseUrl}/admin/coupon/enable`, { id }, options)
			.then((res) => {
				handleSuccess("successfully enabled coupon", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCoupons()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to enable coupon` }, dispatch)
			);
	}
);

export const disableCoupon = createAsyncThunk(
	"coupons/disableCoupon",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(`${config.baseUrl}/admin/coupon/disable`, { id }, options)
			.then((res) => {
				handleSuccess("successfully disabled coupon", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCoupons()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to disable coupon` }, dispatch)
			);
	}
);

export const couponSlice = createSlice({
	name: "coupons",
	initialState: { all: [], status: null },

	extraReducers: {
		[getCoupons.pending]: (state, action) => {
			state.status = "loading";
		},
		[getCoupons.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getCoupons.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deleteCoupon.pending]: (state, action) => {
			state.status = "loading";
		},
		[deleteCoupon.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deleteCoupon.rejected]: (state, action) => {
			state.status = "failed";
		},
		[enableCoupon.pending]: (state, action) => {
			state.status = "loading";
		},
		[enableCoupon.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[enableCoupon.rejected]: (state, action) => {
			state.status = "failed";
		},
		[disableCoupon.pending]: (state, action) => {
			state.status = "loading";
		},
		[disableCoupon.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[disableCoupon.rejected]: (state, action) => {
			state.status = "failed";
		},
		[createCoupon.pending]: (state, action) => {
			state.status = "loading";
		},
		[createCoupon.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[createCoupon.rejected]: (state, action) => {
			state.status = "failed";
		},
		[updateCoupon.pending]: (state, action) => {
			state.status = "loading";
		},
		[updateCoupon.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[updateCoupon.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// export const { getCoupons, changeStatus, editPrice } = courseSlice.actions;
export default couponSlice.reducer;
