import React, { useState } from "react";
import { Btn } from "../../Components/UI/Buttons";
import { useDispatch } from "react-redux";
import { createCoupon } from "../../Redux/couponSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";

// import { modalVariants } from "../../Components/Helpers/animation.js";
const AddCouponModal = ({ closeModal }) => {
	const [details, setDetails] = useState({
		name: null,
		code: null,
		discount: null,
	});

	const { status } = useSelector((state) => state?.coupons);

	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const dispatch = useDispatch();
	const addCoupon = () => {
		if (details?.code && details.discount && details.name) {
			dispatch(createCoupon(details));
			return;
		}
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal ">
				<h2 className="modal__heading heading-sec heading">Add coupon</h2>
				<div className="modal__content coupon__modal">
					<div className="modal__input__group__container">
						<div className="modal__input__group">
							<label htmlFor="name">Coupon name</label>
							<input
								type="text"
								onChange={handleChange}
								name="name"
								id="name"
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="code">Coupon code</label>
							<input
								type="text"
								onChange={handleChange}
								name="code"
								id="code"
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="discount"> discount percentage</label>
							<input
								type="number"
								onChange={handleChange}
								name="discount"
								id="discount"
							/>
						</div>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={addCoupon} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default AddCouponModal;
