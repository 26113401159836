import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AddBtn } from "../../Components/UI/Buttons";
import { Angle, DeleteIcon } from "../../Components/UI/Icons";
import { removeCategory } from "../../Redux/courseSlice";
import Editcategory from "./EditCategory";
const Category = ({ openAddModal }) => {
	const dispatch = useDispatch();
	const deleteCategory = (id) => {
		dispatch(removeCategory(id));
	};

	const { categories, status } = useSelector((state) => state.courses);
	const [isOpen, setIsOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const closeModal = () => {
		setEdit("");
	};
	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);
	return (
		<>
			<AnimatePresence>
				{edit && <Editcategory category={edit} closeModal={closeModal} />}
			</AnimatePresence>
			<div className="category__main">
				<div className="category__header">
					<div className="header__content" onClick={() => setIsOpen(true)}>
						<h2 className="heading heading-sec">Categories</h2>
						<AddBtn handleClick={openAddModal} />
					</div>
					<div
						className="header__content header__content--2"
						onClick={() => setIsOpen(!isOpen)}>
						<div className={`category__header__icon ${!isOpen && "active"}`}>
							<Angle />
						</div>
					</div>
				</div>
				{isOpen && (
					<div className="category__container">
						{categories?.map((category, index) => (
							<li className="category" key={index}>
								<span
									className="category__btn"
									onClick={() => deleteCategory(category?._id)}>
									<DeleteIcon />
								</span>
								<span
									className="category__content"
									onClick={() => setEdit(category)}>
									{category?.name}
								</span>
							</li>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default Category;
