import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BtnLoader } from "../../Components/UI/Loader";
import { getOtp, updatePassword } from "../../Redux/authSlice";
const Newpassword = () => {
	const [details, setDetails] = useState(null);
	const [errors, setErrors] = useState(null);
	const { passwordChanged, status } = useSelector((state) => state.auth);

	const { email } = useSelector((state) => state.auth.otp);
	const dispatch = useDispatch();
	const handleSubmit = (e) => {
		e.preventDefault();

		return dispatch(updatePassword(details));
	};

	const handleChange = (e) => {
		setErrors(null);
		if (e.target.value.length > 3) {
			setDetails({ ...details, [e.target.id]: e.target.value.trim() });
		
		} else {
			setErrors(`please check ${e.target.id}`);
		}
	};

	const reSend = (e) => {
		e.preventDefault();
		dispatch(getOtp({ email }));
	};
	return passwordChanged ? (
		<div className="signin__form" key={"new_password_success"}>
			<div className="signin__form__heading">
				<h1 className="heading heading-pri form__heading">Hi there,</h1>
				<h2
					className="heading heading-sec
								form__heading--2">
					Your password has been changed successfully
				</h2>
			</div>
			<div className="form__group form__submit__container">
				<Link to={"/auth/signin"} className="form__submit btn btn-pri ">
					Continue
				</Link>
			</div>
		</div>
	) : (
		<>
			<form
				className="signin__form"
				key={"change_password_form"}
				onSubmit={handleSubmit}>
				<div className="signin__form__heading">
					<h1 className="heading heading-pri form__heading">Hi there,</h1>
					<h2
						className="heading heading-sec 
								form__heading--2">
						An OTP was sent to the email you provided
					</h2>
				</div>
				<div className="form__group">
					<label htmlFor="otp">otp</label>
					<input
						type="text"
						id="otp"
						name="otp"
						placeholder="*****"
						className="signin__input"
						onChange={handleChange}
					/>
				</div>
				<div className="form__group">
					<label htmlFor="password">password</label>
					<input
						type="text"
						id="newPassword"
						name="newPassword"
						placeholder="Min 8 characters"
						className="signin__input"
						onChange={handleChange}
					/>
					<div className="signin__extra">
						<button className="btn btn-del form__link" onClick={reSend}>
							re-send otp?
						</button>
					</div>
				</div>
				<div className="form__group form__submit__container">
					<button type="submit" className="form__submit btn btn-pri ">
						{status === "loading" ? <BtnLoader /> : "Continue"}
					</button>
				</div>
			</form>

			<div className="form__error__container">{errors ?? ""}</div>
		</>
	);
};

export default Newpassword;
