import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader, { getFileHeader } from "../Helpers/header";
// import header from "../Helpers/header";
import { handleErrors, handleSuccess } from "../Helpers/others";

export const getCohort = createAsyncThunk(
	"cohort/getCohort",
	async (_, { dispatch }) => {
		return axios
			.get(`${config.baseUrl}/admin/course/cohort`, {
				headers: getHeader(),
			})
			.then((res) => res.data)
			.then(({ data }) => data)
			.catch((err) =>
				handleErrors({ err, msg: `failed to load current cohort` }, dispatch)
			);
	}
);
export const setCohort = createAsyncThunk(
	"cohort/setCohort",
	async (details, { dispatch }) => {

		// console.log("gotten url", formData.getAll());
		return axios
			.post(`${config.baseUrl}/admin/course/cohort`, details, {
				headers: getHeader(),
			})
			.then((res) => {
				handleSuccess("successfully set cohort", dispatch);
				console.log("res.data");
				return res.data;
			})
			.then(() => dispatch(getCohort()))
			.catch((err) => {
				console.log(err);
				handleErrors({ err, msg: `failed to set cohort` }, dispatch);
			});
	}
);

export const cohortSlice = createSlice({
	name: "cohort",
	initialState: { all: undefined, status: null },

	extraReducers: {
		[getCohort.pending]: (state, action) => {
			state.status = "loading";
		},
		[getCohort.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getCohort.rejected]: (state, action) => {
			state.status = "failed";
		},
		[setCohort.pending]: (state, action) => {
			state.status = "loading";
		},
		[setCohort.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[setCohort.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default cohortSlice.reducer;
