import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { DeleteBtn } from "../../Components/UI/Buttons";
import Search from "../../Components/UI/Search";
import { useSelector, useDispatch } from "react-redux";
import { deleteComment, getComments } from "../../Redux/blogSlice";
import { useParams } from "react-router";
import { filterItems } from "../../Helpers/filter";
import Loader from "../../Components/UI/Loader";
const Comments = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const blogId = useParams();
	const { status } = useSelector((state) => state.blog);
	const comments = useSelector((state) =>
		state.blog.comments.filter(
			(comment) => comment["blogId"] === String(blogId)
		)
	);
	const [selectedContent, setSelectedContent] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const back = () => {
		navigate(-1);
	};

	useEffect(() => {
		dispatch(getComments());
	}, [dispatch]);

	const showContent = (el) => {
		setSelectedContent(el);
	};

	const searchComments = (value) => {
		setSearchValue(value);
	};

	const removeComment = () => {
		dispatch(
			deleteComment({
				blogId: selectedContent?.blogId,
				id: selectedContent["_id"],
			})
		);
	};
	return comments.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<section className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri link" onClick={back}>
						back
					</h2>
				</div>
			</header>
			<main className="main mail__main">
				<div className="mail__container banner">
					<aside className="mail__overview">
						<div className="mail__overview__header">
							<Search handleSearch={searchComments}>messages</Search>
							<div className="mail__overview__category">
								<span>All</span>
							</div>
						</div>
						<div className="mail__overview__body">
							{searchValue
								? comments
										?.filter((comment) =>
											filterItems(
												comment?.comment,
												comment?.name,
												comment?.email,
												searchValue
											)
										)
										.map((comment, index) => (
											<div
												className="message"
												key={comment["_id"]}
												onClick={() => {
													showContent(comment);
												}}>
												<div className="message__header">
													<h3 className="message__author mail__author">
														{comment?.name}
													</h3>
													<div className="message__tag">
														{/* 	<button className="btn btn-pri message__tag__btn">
											NEW
										</button> */}
														<span>
															{new Date(
																Date.now() -
																	new Date(comment?.createdAt).getTime()
															).getDay()}
														</span>
														&nbsp; days ago
													</div>
												</div>
												<p className="message__body">{comment?.comment}</p>
											</div>
										))
								: comments?.map((comment, index) => (
										<div
											className="message"
											key={comment["_id"]}
											onClick={() => {
												showContent(comment);
											}}>
											<div className="message__header">
												<h3 className="message__author mail__author">
													{comment?.name}
												</h3>
												<div className="message__tag">
													{/* 	<button className="btn btn-pri message__tag__btn">
											NEW
										</button> */}
													<span>
														{new Date(
															Date.now() -
																new Date(comment?.createdAt).getTime()
														).getDay()}
													</span>
													&nbsp; days ago
												</div>
											</div>
											<p className="message__body">{comment?.comment}</p>
										</div>
								  ))}
						</div>
					</aside>
					<section className="mail__content comment__content">
						<div className="mail__details comment__details">
							<div className="mail__header">
								<div className="mail__header__info">
									<h4 className="mail__author heading">
										{selectedContent?.name}
									</h4>
									<h4 className="mail__author__email email">
										{selectedContent?.email}
									</h4>
								</div>
								<div className="mail__header__action">
									<DeleteBtn handleClick={removeComment}>Delete</DeleteBtn>
								</div>
							</div>
							<div className="mail__body">{selectedContent?.comment}</div>
						</div>
					</section>
				</div>
			</main>
		</section>
	);
};

export default Comments;
