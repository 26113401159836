import React, { useEffect, useState } from "react";
import Search from "../../Components/UI/Search";
import "./Instructors.scss";
import Toggle from "../../Components/UI/Toggle";
import { AddBtn } from "../../Components/UI/Buttons";
import {
	getInstructors,
	disableInstructor,
	enableInstructor,
} from "../../Redux/instructorSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { filterUser } from "../../Helpers/filter";
import { imageFallback } from "../../Helpers/others";
import AddInstructor from "./AddInstructor";
import Loader from "../../Components/UI/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants, courseVariants } from "../../Helpers/animation";

const Instructors = () => {
	const [searchValue, setSearchValue] = useState("");
	const { all: instructors, status } = useSelector(
		(state) => state.instructors
	);
	const [add, showAdd] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getInstructors());
	}, [ dispatch ] );
	
	const closeModal = () => {
		showAdd(false);
	};

	const changeStatus = ({ status, id }) => {
		if (status) {
			dispatch(enableInstructor(id));
		} else {
			dispatch(disableInstructor(id));
		}
	};

	const searchInstructors = (value) => {
		setSearchValue(value);
	};
	const openAddModal = () => {
		showAdd(true);
	};

	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);

	return instructors?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<section className="container">
			<AnimatePresence exitBeforeEnter>
				{add && <AddInstructor closeModal={closeModal} />}
			</AnimatePresence>
			<div className="header">
				<div className="header__content">
					<h2 className="heading-pri">All Instructors</h2>
					<AddBtn handleClick={openAddModal} />
				</div>
				<div className="header__context">
					<Search handleSearch={searchInstructors}>instructors</Search>
				</div>
			</div>
			<motion.main
				variants={containerVariants}
				initial="start"
				animate="end"
				exit="exit"
				className="instructor__container grid main">
				<AnimatePresence exitBeforeEnter>
					{searchValue
						? instructors
								.filter((instructor) => filterUser(instructor, searchValue))
								.map((instructor) => (
									<motion.div
										variants={courseVariants}
										className="instructor"
										key={instructor["_id"]}>
										<div className="instructor__image">
											<img
												src={instructor?.profileImg ?? ""}
												onError={imageFallback}
												alt="instructor"
												className="instructor__img"
											/>
										</div>
										<div className="instructor__details">
											<h3 className="heading heading-sec instructor__name">
												{instructor?.firstName}&nbsp; {instructor?.lastName}
											</h3>

											<h3 className="heading heading-ter--2 instructor__email">
												{instructor?.email}
											</h3>
											<h3 className="instructor__courses">
												{instructor?.courses.length === 1 ? (
													<span>1 course</span>
												) : (
													<>
														{instructor?.courses?.length} <span>courses</span>
													</>
												)}
											</h3>
											<div className="instructor__options ">
												<div className="instructor__contact subtle">{""}</div>

												<Toggle
													value={instructor?.isActive}
													handleChange={changeStatus}
													id={instructor["_id"]}
												/>
											</div>
										</div>
									</motion.div>
								))
						: instructors?.map((instructor) => (
								<motion.div
									variants={courseVariants}
									className="instructor"
									key={instructor["_id"]}>
									<div className="instructor__image">
										<img
											src={instructor?.profileImg ?? ""}
											onError={imageFallback}
											alt="instructor"
											className="instructor__img"
										/>
									</div>
									<div className="instructor__details">
										<h3 className="heading heading-sec instructor__name">
											{instructor?.firstName}&nbsp; {instructor?.lastName}
										</h3>

										<h3 className="heading heading-ter--2 instructor__email">
											{instructor?.email}
										</h3>
										<h3 className="instructor__courses">
											{instructor?.courses.length === 1 ? (
												<span>1 course</span>
											) : (
												<>
													{instructor?.courses?.length} <span>courses</span>
												</>
											)}
										</h3>
										<div className="instructor__options ">
											<div className="instructor__contact subtle">{""}</div>

											<Toggle
												value={instructor?.isActive}
												handleChange={changeStatus}
												id={instructor["_id"]}
											/>
										</div>
									</div>
								</motion.div>
						  ))}
				</AnimatePresence>
			</motion.main>
		</section>
	);
};

export default Instructors;
