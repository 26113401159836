import React, { useState } from "react";
import "./Select.scss";
import { Angle } from "./Icons";
const Select = ({ options, handleChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState(null);
	const toggleSelect = () => setIsOpen(!isOpen);
	const setOption = (option, sort) => {
		setValue(option);
		handleChange(sort);
		setIsOpen(false);
	};

	return (
		<div className="select__container">
			<div className="select">
				<div className="select__header" onClick={toggleSelect}>
					<div className="select__header__value">
						<strong>Sort by :</strong>
						&nbsp;
						<span>{value ?? ""}</span>
					</div>
					<div className={`select__header__icon ${isOpen && "active"}`}>
						<Angle />
					</div>
				</div>
				<div className="select__list__container">
					{isOpen && (
						<ul className={`select__list ${isOpen && "active"}`} id="select">
							{options?.map(({ type, sort }, index) => (
								<li
									className="select__list__item"
									key={index}
									onClick={() => {
										setOption(type, sort);
									}}>
									{type}
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default Select;
