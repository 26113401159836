import React, { useEffect, useState } from "react";
import "./Subscribers.scss";
import Search from "../../Components/UI/Search";
import { DeleteBtn, Btn } from "../../Components/UI/Buttons";
import { getSubscribers, deleteSubscriber } from "../../Redux/subscriberSlice";
import { useDispatch, useSelector } from "react-redux";
import { filterItems } from "../../Helpers/filter";
import SubscriberModal from "./SubscriberModal";
import Loader from "../../Components/UI/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants, courseVariants } from "../../Helpers/animation";

const Subscribers = () => {
	const { all: subscribers, status } = useSelector(
		(state) => state.subscribers
	);
	const [searchValue, setSearchValue] = useState("");
	const dispatch = useDispatch();
	const [mailModal, showMailModal] = useState(false);
	useEffect(() => {
		dispatch(getSubscribers());
	}, [dispatch]);

	const removeSubscriber = (id) => {
		dispatch(deleteSubscriber(id));
	};
	const searchSubscribers = (value) => {
		setSearchValue(value);
	};
	const closeModal = () => {
		showMailModal(false);
	};
	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);
	return subscribers?.length < 1 ? (
		<Loader />
	) : (
		<div className="container">
			<AnimatePresence exitBeforeEnter>
				{mailModal && <SubscriberModal closeModal={closeModal} />}
			</AnimatePresence>
			<header className="header">
				<div className="header">
					<div className="header__content">
						<h2 className="heading-pri">All Subscribers</h2>
						<Btn handleClick={() => showMailModal(true)}>mail subscribers</Btn>
					</div>
					<Search handleSearch={searchSubscribers}>Subscribers</Search>
				</div>
			</header>

			<main className="main">
				<div className="table__container">
					<table className="table">
						<thead className="table__head">
							<tr className="table__row">
								<th className="subscriber__serial__head">S/N</th>
								<th className="subscriber__name__head">Email</th>
								<th className="subscriber__code__head">Action</th>
							</tr>
						</thead>
						<motion.tbody
							variants={containerVariants}
							initial="start"
							animate="end"
							exit="exit"
							className="table__body">
							<AnimatePresence exitBeforeEnter>
								{searchValue
									? subscribers
											.filter((subscriber) =>
												filterItems(
													subscriber?.name,
													subscriber?.email,
													"",
													searchValue
												)
											)
											?.map((subscriber, index) => (
												<motion.tr
													variants={courseVariants}
													className="table__row subscriber"
													key={subscriber["_id"]}>
													<td className="serial__number">
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</td>
													<td className="subscriber__email email">
														{subscriber?.email}
													</td>

													<td className="subscriber__action">
														<DeleteBtn
															handleClick={() =>
																removeSubscriber(subscriber["_id"])
															}
														/>
													</td>
												</motion.tr>
											))
									: subscribers?.map((subscriber, index) => (
											<motion.tr
												variants={courseVariants}
												className="table__row subscriber"
												key={index}>
												<td className="serial__number">
													{(index + 1).toLocaleString("en-US", {
														minimumIntegerDigits: 2,
														useGrouping: false,
													})}
												</td>
												<td className="subscriber__email email">
													{subscriber?.email}
												</td>

												<td className="subscriber__action">
													<DeleteBtn
														handleClick={() =>
															removeSubscriber(subscriber["_id"])
														}
													/>
												</td>
											</motion.tr>
									  ))}
							</AnimatePresence>
						</motion.tbody>
					</table>
				</div>
			</main>
		</div>
	);
};

export default Subscribers;
