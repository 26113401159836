import React, { useEffect } from "react";
import "./TutorRequest.scss";
import { useDispatch, useSelector } from "react-redux";
import { getInstructors } from "../../Redux/instructorSlice";
import Loader from "../../Components/UI/Loader";
import { motion } from "framer-motion";
import { containerVariants, courseVariants } from "../../Helpers/animation";
const TutorRequest = () => {
	const { all: tutors } = useSelector((state) => state.instructors);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getInstructors());
	}, [dispatch]);
	return tutors?.length < 1 ? (
		<Loader />
	) : (
		<div className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">Tutor request</h2>
					<div className="header__content"></div>
				</div>
			</header>

			<main className="main">
				<div className="table__container">
					<table className="table">
						<thead className="table__head">
							<tr className="table__row">
								<th className="tutor__name__head">Name</th>
								<th className="tutor__email__head">Email</th>
								<th className="tutor__completed__phone">Phone Number</th>
								<th className="tutor__pending__course">Course</th>
								<th className="tutor__pending__course">Category</th>
							</tr>
						</thead>
						<motion.tbody
							variants={containerVariants}
							initial="start"
							animate="end"
							className="table__body">
							{tutors
								?.filter((tutor) => tutor.isActive)
								?.map((tutor, index) => (
									<motion.tr variants={courseVariants} className="table__row">
										<td className="tutor__name">
											{tutor?.firstName} {tutor?.lastName}
										</td>
										<td className="tutor__email">{tutor?.email}</td>
										<td className="tutor__phone"></td>

										<td className="tutor__course">
											{tutor.course ? tutor.course[0] : ""}
										</td>
										<td className="tutor__category">{""}</td>
									</motion.tr>
								))}
						</motion.tbody>
					</table>
				</div>
			</main>
		</div>
	);
};

export default TutorRequest;
