import defaultImg from "../Assets/default.png";
import { alertError, alertSuccess } from "../Redux/alertSlice";
export const imageFallback = (e) => {
	e.target.src = defaultImg;
};

export const handleErrors = ({ err, msg }, dispatch) => {
	if (err.response) {
		// Request made and server responded
		/* 
		console.log(err.response.data);
		console.log(err.response.status);
		console.log(err.response.headers); */
		dispatch(
			alertError({ msg, error: err.response.data.errors.replaceAll("_", " ") })
		);
	} else if (err.request) {
		// The request was made but no response was received
		// console.log(err.message);
		dispatch(alertError(msg + ",  " + err.message));
	} else {
		// Something happened in setting up the request that triggered an Error
		dispatch(alertError(msg + ",  " + err.message));
	}
};

export const handleSuccess = (msg, dispatch) => {
	// console.log(msg);
	dispatch(alertSuccess(msg));
};


