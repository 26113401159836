import React, { useEffect, useState } from "react";
import "./index.scss";
import Search from "../../Components/UI/Search";
import { EditBtn, DeleteBtn, Btn } from "../../Components/UI/Buttons";
import Toggle from "../../Components/UI/Toggle";
import { AddBtn } from "../../Components/UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";

import {
    enrollStudents,
    sendUrl
} from "../../Redux/autoEnrollSlice"
import { filterItems } from "../../Helpers/filter";
import Loader from "../../Components/UI/Loader";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants, courseVariants } from "../../Helpers/animation";

const AutoEnroll = () => {
    const dispatch = useDispatch();
    const { all: autoEnroll, status } = useSelector((state) => state.autoEnroll);
    const [csvData, setCsvData] = useState([])
    const [csvFile, setCsvFile] = useState()
    // useEffect(() => {
    //     dispatch(getCoupons());
    // }, [dispatch]);

    const autoEnrollStudents = async (file) => {
        await autoEnroll?.map(async (item) => {
            await dispatch(sendUrl({ url: item?.url }))
        })
    };
    const handleFileUpload = async (e) => {
        const files = e.target.files;
        console.log(files);
        if (files) {
            console.log(files[0]);
            await dispatch(enrollStudents(files[0]));
            setCsvFile(files[0])
            Papa.parse(files[0], {
                complete: function (results) {
                    console.log("Finished:", results.data);
                    setCsvData(results?.data)
                }
            }
            )
        }
    };

    return status === "loading" ? (
        <Loader />
    ) : (
        <div class Name="container">

            <header className="header">
                <div className="header">
                    <div className="header__content">
                        <h2 className="heading-pri mr-2">Auto Enroll</h2>
                        <input
                            type="file"
                            accept=".csv,.xlsx,.xls"
                            onChange={handleFileUpload}
                        />

                    </div>
                    <div>

                        <Btn handleClick={() => autoEnrollStudents(csvFile)}>Enroll</Btn>
                    </div>
                </div>
            </header>

            <main className="main">
                <div className="table__container">
                    {
                        csvData && csvData?.length > 0 &&
                        <table className="table">
                            <thead className="table__head">
                                <tr className="table__row">
                                    {
                                        csvData[0]?.map((elem, index) => (
                                            <th className="enroll__name__head" key={index}>{elem}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    csvData?.slice(1, csvData.length)?.map((elem, idx) => (
                                        <tr key={idx}>
                                            {
                                                elem?.map((item, index) => (
                                                    <td key={index}>{item}</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </main>
        </div>
    );
};

export default AutoEnroll;
