import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader, { getFileHeader } from "../Helpers/header";
// import header from "../Helpers/header";
import { handleErrors, handleSuccess } from "../Helpers/others";

export const sendUrl = createAsyncThunk(
    "autoEnroll/sendUrl",
    async (details, { dispatch }) => {

        // console.log("gotten url", formData.getAll());
        return axios
            .post(`${config.baseUrl}/auto/enroll`, details, {
                headers: getHeader(),
            })
            .then((res) => {
                handleSuccess("successfully enrolled students", dispatch);
                console.log(res.data);
            })
            // .then(() => dispatch(getCohort()))
            .catch((err) => {
                console.log(err);
                handleErrors({ err, msg: `failed to enroll students` }, dispatch);
            });
    }
);


export const enrollStudents = createAsyncThunk(
    "autoEnroll/enrollStudents",
    async (file, dispatch) => {
        const formData = new FormData();
        formData.append("file", file);

        return axios
            .post(`${config.baseUrl}/v1/file/upload/${config.token}`, formData, {
                headers: getFileHeader(),
            })
            .then(({ data }) => {
                
                return data?.data
            })
            // .then(() => dispatch(getCohort()))
            .catch((err) => {
                console.log(err);
                handleErrors({ err, msg: `failed to enroll students` }, dispatch);
            });
    }
);


export const autoEnrollSlice = createSlice({
    name: "autoEnroll",
    initialState: { all: undefined, status: null },

    extraReducers: {
        [enrollStudents.pending]: (state, action) => {
            state.status = "loading";
        },
        [enrollStudents.fulfilled]: (state, { payload }) => {
            state.all = payload;
            state.status = "success";
        },
        [enrollStudents.rejected]: (state, action) => {
            state.status = "failed";
        },
        [sendUrl.pending]: (state, action) => {
            state.status = "loading";
        },
        [sendUrl.fulfilled]: (state, { payload }) => {
            state.status = "success";
        },
        [sendUrl.rejected]: (state, action) => {
            state.status = "failed";
        },
    },
});

export default autoEnrollSlice.reducer;
