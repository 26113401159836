import { createSlice } from "@reduxjs/toolkit";
export const alertSlice = createSlice({
	name: "alert",
	initialState: {
		type: "",
		message: "",
		error: "",
	},
	reducers: {
		alertClear: (state, action) => {
			return {
				type: "clear",
				message: "",
			};
		},
		alertSuccess: (state, action) => {
			return {
				type: "success",
				message: action.payload,
			};
		},
		alertError: (state, action) => {
			return {
				type: "error",
				message: action.payload.msg,
				error: action.payload.error,
			};
		},
	},
});

export const { alertClear, alertError, alertSuccess } = alertSlice.actions;
export default alertSlice.reducer;
