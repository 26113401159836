import React, { useEffect, useState, useRef } from "react";
import "./Toggle.scss";

const Toggle = ({ value, id, handleChange }) => {
	const [toggle, setToggle] = useState(false);
	const toggleSwitch = useRef(null);
	useEffect(() => {
		setToggle(value);
		toggleSwitch.current.checked = value;
	}, [setToggle, value]);
	const changeToggle = (e) => {
		setToggle(e.target.checked ?? toggle);
		handleChange({ status: e.target.checked, id });
	};

	return (
		<div className="toggle__container">
			<input
				type="checkbox"
				name="toggle__checkbox"
				id={`toggle_${id}`}
				ref={toggleSwitch}
				onChange={changeToggle}
			/>
			<label htmlFor={`toggle_${id}`} className="toggle"></label>
		</div>
	);
};

export default Toggle;
