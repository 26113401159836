import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import err from "../../Assets/error.png";
import success from "../../Assets/success.png";
import "./Alert.scss";
import { motion } from "framer-motion";
import { alertVariants } from "../../Helpers/animation";
import { useDispatch } from "react-redux";
import { alertClear } from "../../Redux/alertSlice";
const Alert = () => {
	const { type, message, error } = useSelector((state) => state.alert);
	const dispatch = useDispatch();
	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(alertClear());
		}, 5000);

		return () => {
			clearTimeout(timer);
		};
	}, [message, dispatch]);

	const closeAlert = () => {
		dispatch(alertClear());
	};
	return (
		<AnimatePresence>
			{message &&
				(type === "error" ? (
					<motion.div
						variants={alertVariants}
						initial="start"
						animate="end"
						exit="exit"
						className="alert__container">
						<div className="alert">
							<div className="alert__header" onClick={closeAlert}>
								<span>x</span>
							</div>

							<div className="alert__content">
								<div className="alert__icon ">
									<img src={err} alt="error" className="alert__icon__img" />
								</div>
								<div className="alert__content--2">
									<div className="alert__heading">{error ?? ""}</div>
									<div className="alert__text">
										<p className="paragraph">{message}</p>
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				) : (
					<motion.div
						variants={alertVariants}
						initial="start"
						animate="end"
						exit="exit"
						className="alert__container">
						<div className="alert alert__success">
							<div className="alert__header" onClick={closeAlert}>
								<span>x</span>
							</div>
							<div className="alert__content">
								<div className="alert__icon">
									<img src={success} alt="error" className="alert__icon__img" />
								</div>
								<div className="alert__content--2">
									<div className="alert__heading">{error ?? ""}</div>
									<div className="alert__text">
										<p className="paragraph">{message}</p>
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				))}
		</AnimatePresence>
	);
};

export default Alert;
