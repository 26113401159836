import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Btn } from "../../Components/UI/Buttons";
import Uploader from "../../Components/Uploader/Uploader";
import { editVideo } from "../../Redux/orientationSlice";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";
import { useSelector } from "react-redux";
const EditOrientation = ({ orientation, closeModal }) => {
	const [details, setDetails] = useState({
		length: orientation.length,

		title: orientation.title,
	});
	const { status } = useSelector((state) => state.orientation);
	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};
	const dispatch = useDispatch();
	const editOrientation = () => {
		if (!details) {
			return;
		}

		dispatch(editVideo({ ...details, orientationId: orientation["_id"] }));
	};
	const handleUpload = (upload) => {
		setDetails({
			...details,
			video: upload,
		});
	};

	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal orientation__modal">
				<h2 className="modal__heading heading-sec heading">Edit Video</h2>
				<div className="modal__content coupon__modal ">
					<div className="modal__input__group__container">
						<div className="modal__input__content">
							<div className="modal__input__group">
								<label htmlFor="name">Title</label>
								<input
									type="text"
									onChange={handleChange}
									name="title"
									id="title"
									className="orientation__input"
									defaultValue={orientation?.title}
								/>
							</div>
							<div className="modal__input__group">
								<label htmlFor="code">Video length</label>
								<input
									type="text"
									onChange={handleChange}
									name="length"
									id="length"
									className="orientation__input"
									defaultValue={orientation?.length}
								/>
							</div>
						</div>
						<div className="modal__input__content">
							<div className="modal__upload__group">
								<Uploader
									handleUpload={handleUpload}
									fileType={".mp4"}
									text={"Upload video"}></Uploader>
							</div>
						</div>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={editOrientation} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default EditOrientation;
