import React from "react";
import { NavLink as Link } from "react-router-dom";
import "./Sidebar.scss";

import logo from "../../Assets/mobile-logo.png";
import * as config from "../../Config.json";
import {
	CourseIcon,
	InstructorIcon,
	UserIcon,
	CouponIcon,
	LandingIcon,
	TestimonialIcon,
	SubscriberIcon,
	BlogIcon,
	TutorIcon,
	OrganizationIcon,
	HonourIcon,
	OrientationIcon,
	ContactIcon,
	SignoutIcon,
} from "../UI/Icons";
const Sidebar = ({ signOut }) => {
	return (
		<div className="sidebar">
			<div className="sidebar__logo">
				<img src={logo} className="sidebar__logo__img" alt="logo" />
			</div>
			<ul className="sidebar__list">
			
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/courses`}
						className="sidebar__link"
						activeclassname="active">
						<CourseIcon />

						<span>All Courses</span>
					</Link>
				</li>

				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/instructors`}
						className="sidebar__link"
						activeclassname="active">
						<InstructorIcon />
						<span>All Instructors</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/users`}
						className="sidebar__link"
						activeclassname="active">
						<UserIcon />
						<span>All Users</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/cohort`}
						className="sidebar__link"
						activeclassname="active">
						<CourseIcon />

						<span>Cohort Details</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/auto-enroll`}
						className="sidebar__link"
						activeclassname="active">
						<CourseIcon />

						<span>Auto Enroll</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/coupons`}
						className="sidebar__link"
						activeclassname="active">
						<CouponIcon />
						<span>Coupons</span>
					</Link>
				</li>

				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/landing-page`}
						className="sidebar__link"
						activeclassname="active">
						<LandingIcon />
						<span>Landing page</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/testimonials`}
						className="sidebar__link"
						activeclassname="active">
						<TestimonialIcon />
						<span>Testimonials</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/subscribers`}
						className="sidebar__link"
						activeclassname="active">
						<SubscriberIcon />
						<span>Subscribers</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/blog`}
						className="sidebar__link"
						activeclassname="active">
						<BlogIcon />
						<span>Blog</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/tutor-request`}
						className="sidebar__link"
						activeclassname="active">
						<TutorIcon />
						<span>Tutor request</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/organization`}
						className="sidebar__link"
						activeclassname="active">
						<OrganizationIcon />
						<span>Organization</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/honor-code`}
						className="sidebar__link"
						activeclassname="active">
						<HonourIcon />
						<span>Honor code</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/orientation`}
						className="sidebar__link"
						activeclassname="active">
						<OrientationIcon />
						<span>Orientation</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<Link
						to={`/${config.dashboard}/contact-us`}
						className="sidebar__link"
						activeclassname="active">
						<ContactIcon />
						<span>Contact us</span>
					</Link>
				</li>
				<li className="sidebar__item">
					<button className="btn sidebar__link" onClick={signOut}>
						<SignoutIcon />
						<span>Sign out</span>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default Sidebar;
