import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Btn } from "../../Components/UI/Buttons";
import { createInstructor } from "../../Redux/instructorSlice";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";
const AddInstructor = ({ closeModal }) => {
	const [details, setDetails] = useState({
		firstName: null,
		lastName: null,
		email: null,
	});
	const { status } = useSelector((state) => state.instructors);
	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const dispatch = useDispatch();
	const addInstructor = () => {
		if (details?.firstName && details.lastName && details.email) {
			dispatch(createInstructor(details));
			return;
		}
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal instructor__modal">
				<h2 className="modal__heading heading-sec heading">Add Instructor</h2>
				<div className="modal__content instructor__modal__content">
					<div className="modal__input__group__container">
						<div className="modal__input__group">
							<label htmlFor="name">First name</label>
							<input
								type="text"
								onChange={handleChange}
								name="firstName"
								id="firstname"
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="code">Last name</label>
							<input
								type="text"
								onChange={handleChange}
								name="lastName"
								id="lastname"
							/>
						</div>
					</div>
					<div className="modal__input__group modal__input__group--2">
						<label htmlFor="discount"> Email</label>
						<input
							type="email"
							onChange={handleChange}
							name="email"
							id="email"
							className="input--2"
						/>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={addInstructor} status={status}>
						{"Submit"}
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default AddInstructor;
