import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Btn } from "../../Components/UI/Buttons";
import { modalVariants } from "../../Helpers/animation";
import { addCategory } from "../../Redux/courseSlice";
import Uploader from "../../Components/Uploader/Uploader";
const Addcategory = ({ closeModal }) => {
	const [details, setDetails] = useState({});
	const { status } = useSelector((state) => state.courses);
	const dispatch = useDispatch();
	const handleChange = (e) => {
		if (e.target.name === "name") {
			setDetails({
				...details,
				[e.target.name]: e.target.value.trim(),
				group: e.target.value.trim(),
			});

			return;
		}

		setDetails({ ...details, [e.target.name]: e.target.value.trim() });
	};

	const handleSubmit = () => {
		if (!details) {
			return;
		}

		dispatch(addCategory(details));
	};

	const handleImage = (image) => {
		console.log(image);
		setDetails({ ...details, image });
	};

	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal course__modal category__modal">
				<h2 className="heading-sec modal__heading heading"> Add Category</h2>
				<div className="modal__content">
					<div className="modal__input__group">
						<label htmlFor="price">name</label>
						<input
							type="text"
							name="name"
							id="name"
							placeholder="new category"
							onChange={handleChange}
						/>
					</div>
					<div className="modal__input__group">
						<label htmlFor="price">description</label>
						<input
							type="text"
							name="description"
							id="description"
							placeholder="description"
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="category__uploader">
					<Uploader handleUpload={handleImage}>featured Image</Uploader>
				</div>

				<div className="modal__footer">
					<button
						className="btn btn-cancel"
						onClick={(e) => {
							closeModal();
						}}>
						Cancel
					</button>
					<Btn handleClick={handleSubmit} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default Addcategory;
