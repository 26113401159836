import React, { useState, useCallback } from "react";
import "./Uploader.scss";
import { UploadIcon } from "../UI/Icons";
import { useDropzone } from "react-dropzone";

const Uploader = ({ fileType, handleUpload, children, text, image = null }) => {
	const [upload, setUpload] = useState(image);
	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];
			setUpload(file);
			handleUpload(file);
			/* const reader = new FileReader();
			console.log("file from reader", file);

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onloadend = (res) => {
				console.log("reader result", res);
				 */ // Do whatever you want with the file contents
			/* 	const binaryStr = reader.result;
					const base64Str = Buffer.from(binaryStr, "utf8").toString("base64");
					setUpload({
						name: file.name,
						base64Str,
					}); */
			// };
		},
		[handleUpload]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	return (
		<>
			<h3 className="heading heading-sec pl-1">{children}</h3>

			<div className="uploader " {...getRootProps()}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}>
					<input
						type="file"
						name="uploader-file"
						id="uploader-file"
						className="uploader__file"
						placeholder="file"
						{...getInputProps()}
						accept={fileType ?? ".png,.jpeg,.jpg"}
					/>
				</form>
				<div className="uploader__action">
					{upload ? (
						<>
							<h3 className="uploader__text heading ">
								{upload?.name ?? "error reading file"}
							</h3>
						</>
					) : isDragActive ? (
						<h3 className="uploader__text heading ">Drop files here</h3>
					) : (
						<>
							<div className="uploader__action__image">
								<UploadIcon />
							</div>
							<h3 className="uploader__text heading ">
								{text ?? "Drag & drop file"}
							</h3>
							<label
								htmlFor="#uploader-file"
								className="btn btn-pri uploader__action__btn">
								Browse
							</label>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Uploader;
