export const filterUser = (user, searchValue) => {
	if (
		user?.firstName?.toLowerCase().includes(searchValue.split(" ")[0]) ||
		user?.firstName?.toLowerCase().includes(searchValue.split(" ")[1])
	) {
		return true;
	} else if (
		user?.lastName?.toLowerCase().includes(searchValue.split(" ")[0]) ||
		user?.lastName?.toLowerCase().includes(searchValue.split(" ")[1])
	) {
		return true;
	} else {
		return false;
	}
};

export const filterItems = (val1, val2, val3, searchValue) => {
	if (
		String(val1).toLowerCase().includes(searchValue.split(" ")[0]) ||
		String(val1).toLowerCase().includes(searchValue.split(" ")[1])
	) {
		return true;
	} else if (
		String(val2).toLowerCase().includes(searchValue.split(" ")[0]) ||
		String(val2).toLowerCase().includes(searchValue.split(" ")[1])
	) {
		return true;
	} else if (
		String(val3).toLowerCase().includes(searchValue.split(" ")[0]) ||
		String(val3).toLowerCase().includes(searchValue.split(" ")[1])
	) {
		return true;
	} else {
		return false;
	}
};
