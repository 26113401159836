import React, { useEffect, useState } from "react";
import "./Testimonials.scss";
import { Quote, HamburgerIcon } from "../../Components/UI/Icons";
import { AddBtn } from "../../Components/UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
	getTestimonials,
	deleteTestimonial,
	setCover,
} from "../../Redux/testimonialSlice";
import { imageFallback } from "../../Helpers/others";
import EditTestimonial from "./EditTestimonial";
import AddTestimonial from "./AddTestimonial";
import { AnimatePresence, motion } from "framer-motion";

import { containerVariants, courseVariants } from "../../Helpers/animation";
import { BtnLoader } from "../../Components/UI/Loader";
const Testimonials = () => {
	const { all: testimonials, status } = useSelector(
		(state) => state.testimonials
	);
	const cover = useSelector(
		(state) => state.landing?.details?.data?.testimonial
	);

	const [edit, setEdit] = useState(false);
	const [addModal, showAddModal] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getTestimonials());
	}, [dispatch]);

	const handleDelete = (id) => {
		dispatch(deleteTestimonial(id));
	};

	const handleUpload = (e) => {
		if (e.target.files) {
			dispatch(setCover(e.target.files[0]));
			return;
		}
	};
	const handleEdit = (testimonial) => {
		setEdit(testimonial);
	};

	const closeModal = () => {
		setEdit(false);
		showAddModal(false);
	};
	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);
	return (
		<div className="container">
			<AnimatePresence exitBeforeEnter>
				{edit && <EditTestimonial testimonial={edit} closeModal={closeModal} />}
				{addModal && <AddTestimonial closeModal={closeModal} />}
			</AnimatePresence>
			<header className="header">
				<div className="header">
					<div className="header__content">
						<h2 className="heading-pri">Testimonials</h2>
						<AddBtn handleClick={() => showAddModal(true)} />
					</div>
				</div>
			</header>
			<main className=" main">
				<div className="testimonial__cover">
					<h2 className="card__heading heading">Cover Video</h2>
					<div className="testimonial__cover__video__container">
						{status === "loading" ? (
							<BtnLoader />
						) : cover ? (
							<video className="testimonial__cover__video" controls>
								<source src={cover ?? ""} onError={imageFallback}></source>
							</video>
						) : (
							<></>
						)}
					</div>

					<div className="card__media__actions">
						<div className="file__container">
							<input
								type="file"
								id="testimonial-cover"
								name="cover"
								className="file"
								onChange={handleUpload}
								accept=".mp4"
							/>

							<label
								htmlFor="testimonial-cover"
								className="btn card__media__action__link">
								replace
							</label>
						</div>
						{/* 	<button
							className="btn card__media__action__link "
							onClick={handleUpload}>
							delete
						</button> */}
					</div>
				</div>

				<motion.div
					variants={containerVariants}
					initial="start"
					animate="end"
					exit="exit"
					key={"testimonial_container"}
					className="testimonial__container grid">
					<AnimatePresence exitBeforeEnter>
						{testimonials?.map((testimonial) => (
							<motion.div
								variants={courseVariants}
								className="testimonial"
								key={testimonial["_id"]}>
								<div className="testimonial__header">
									<div className="testimonial__icon">
										<Quote />
									</div>

									<div className="testimonial__action">
										<div className="dropdown">
											<input
												type="checkbox"
												name="dropdown_checkbox"
												id={`dropdown_${testimonial["_id"]}`}
											/>
											<label
												htmlFor={`dropdown_${testimonial["_id"]}`}
												className="dropdown__toggle">
												<HamburgerIcon />
											</label>

											<div className="dropdown__list__container">
												<div className="dropdown__list">
													<li className="dropdown__item">
														<button
															className="btn dropdown__btn"
															onClick={() => handleEdit(testimonial)}>
															Edit
														</button>
													</li>
													<li className="dropdown__item">
														<button
															className="btn dropdown__btn"
															onClick={() => handleDelete(testimonial["_id"])}>
															delete
														</button>
													</li>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p className="testimonial__text">{testimonial?.testimonial}</p>
								<div className="testimonial__author">
									<div className="testimonial__author__image">
										<img
											src={testimonial?.image ?? ""}
											alt="testimonial author"
											onError={imageFallback}
											className="testimonial__author__img"
										/>
									</div>
									<div className="testimonial__author__details">
										<h2 className="heading testimonial__name">
											{testimonial?.name}
										</h2>
										<span className="testimonial__role">
											{testimonial?.course}
										</span>
									</div>
								</div>
							</motion.div>
						))}
					</AnimatePresence>
				</motion.div>
			</main>
		</div>
	);
};

export default Testimonials;
