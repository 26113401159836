import React, { useEffect, useState } from "react";
import "./Blog.scss";
import Search from "../../Components/UI/Search";
import { EditBtn, DeleteBtn } from "../../Components/UI/Buttons";
import { ChatIcon } from "../../Components/UI/Icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deletePost, getPosts } from "../../Redux/blogSlice";
import { imageFallback } from "../../Helpers/others";
import { filterItems } from "../../Helpers/filter";
import Loader from "../../Components/UI/Loader";
import { containerVariants, courseVariants } from "../../Helpers/animation";
import { AnimatePresence, motion } from "framer-motion";
const Blog = () => {
	const { all: posts, status } = useSelector((state) => state.blog);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState(null);
	const [selectedPost, setSelectedPost] = useState(null);
	useEffect(() => {
		dispatch(getPosts());
	}, [dispatch]);

	const openBlogEdit = (id) => {
		navigate(`edit/${id}`);
	};

	const selectPost = (e, post) => {
		const checkboxes = Array.from(
			document.getElementsByClassName("blog__checkbox")
		);
		checkboxes.forEach((checkbox) => {
			if (e.target.id !== checkbox.id) {
				checkbox.checked = false;
			}
		});

		setSelectedPost(post);
	};

	const openComments = () => {
		selectedPost && navigate(`comments/${selectedPost["_id"]}`);
	};

	const searchBlog = (value) => {
		setSearchValue(value);
	};
	const removePost = (id) => {
		dispatch(deletePost(id));
	};

	return posts?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<section className="container blog__root">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">Blog</h2>

					<div className="header__content">
						<button className="btn btn-sec" onClick={openComments}>
							<ChatIcon />
							<span>manage comments</span>
						</button>
						<Link to={`add`} className="btn btn-pri">
							Add blog post
						</Link>
					</div>
				</div>
			</header>
			<main className="main ">
				<div className="banner blog__container">
					<div className="blog__header">
						<div className="blog__header__left">
							<div className="blog__header-top">
								<Search handleSearch={searchBlog}>blog posts</Search>
							</div>

							<div className="blog__limit">
								<input
									type="checkbox"
									className="blog__checkbox"
									name="blog-limit-checkbox"
									id="blog-limit-checkbox"
								/>

								<label
									htmlFor="blog-limit-checkbox"
									className="blog__checkbox__label ml-2">
									<h3 className="heading">
										Showing {posts?.length ?? 0} posts
									</h3>
								</label>
							</div>
						</div>
					</div>

					<motion.div
						variants={containerVariants}
						initial="start"
						animate="end"
						exit="exit"
						className="blog__content">
						<AnimatePresence>
							{searchValue
								? posts
										?.filter((post) =>
											filterItems(post.title, "", "", searchValue)
										)
										?.map((post, index) => (
											<motion.div
												variants={courseVariants}
												className="blog__post"
												key={post["_íd"] + "!"}>
												<input
													type="checkbox"
													id={`blog__post${index}`}
													className="blog__checkbox"
													onChange={(e) => {
														selectPost(e, post);
													}}
												/>
												<div className="blog__post__details">
													<div className="blog__post__image">
														<img
															src={post?.image ?? ""}
															onError={imageFallback}
															alt={post?.title ?? "post"}
															className="blog__post__img"
														/>
													</div>
													<div className="blog__post__author">
														<h3 className="heading blog__post__heading">
															{post?.title}
														</h3>
														<h4 className="blog__post__text">{post?.author}</h4>
														<h4 className="blog__post__text">
															last edited: {Date(post?.updatedAt)}
														</h4>
													</div>
												</div>
												<div className="blog__post__action">
													<EditBtn
														handleClick={() => {
															openBlogEdit(post["_id"]);
														}}
													/>
													<DeleteBtn
														handleClick={() => removePost(post["_id"])}
													/>
												</div>
											</motion.div>
										))
								: posts?.map((post, index) => (
										<motion.div
											variants={courseVariants}
											className="blog__post"
											key={post["_íd"]}>
											<input
												type="checkbox"
												id={`blog__post${index}`}
												className="blog__checkbox"
												onChange={(e) => {
													selectPost(e, post);
												}}
											/>
											<div className="blog__post__details">
												<div className="blog__post__image">
													<img
														src={post?.image ?? ""}
														onError={imageFallback}
														alt={post?.title ?? "post"}
														className="blog__post__img"
													/>
												</div>
												<div className="blog__post__author">
													<h3 className="heading blog__post__heading">
														{post?.title}
													</h3>
													<h4 className="blog__post__text">{post?.author}</h4>
													<h4 className="blog__post__text">
														last edited: {Date(post?.updatedAt)}
													</h4>
												</div>
											</div>
											<div className="blog__post__action">
												<EditBtn
													handleClick={() => {
														openBlogEdit(post["_id"]);
													}}
												/>
												<DeleteBtn
													handleClick={() => removePost(post["_id"])}
												/>
											</div>
										</motion.div>
								  ))}
						</AnimatePresence>
					</motion.div>
				</div>
			</main>
		</section>
	);
};

export default Blog;
