import React from "react";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
export const AuthWrapper = ({ children }) => {
	const { user } = useSelector((state) => state.auth);
	const token = localStorage.getItem("admin");
	const location = window.location;
	console.log(location);

	if (user && token) {
		return <Navigate to={"/dashboard"} replace />;
	}

	return children;
};

const Auth = () => {
	return (
		<section className="signin__container">
			<div className="signin">
				<div className="signin__banner">
					<div className="logo"></div>
				</div>

				<div className="signin__form__container">
					<Outlet />
				</div>
			</div>
		</section>
	);
};

export default Auth;
