import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader from "../Helpers/header";
import { handleErrors } from "../Helpers/others";

export const getBooking = createAsyncThunk(
	"organization/getBooking",

	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/booking/all`, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to load bookings	`,
					},
					dispatch
				)
			);
	}
);

export const organizationSlice = createSlice({
	name: "organization",
	initialState: { all: [], status: null },

	extraReducers: {
		[getBooking.pending]: (state, action) => {
			state.status = "loading";
		},
		[getBooking.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getBooking.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default organizationSlice.reducer;
