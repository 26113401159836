import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";
import Topbar from "./Topbar/Topbar";
import Sidebar from "./Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { getProfile } from "../Redux/authSlice";
import SignOut from "../Routes/SignIn/SignOut";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import Alert from "./Alert/Alert";
export const DashboardWrapper = ({ children }) => {
	const token = localStorage.getItem("admin");
	const { user } = useSelector((state) => state.auth);

	if (user || token) {
		return <>{children}</>;
	}

	return <Navigate to={"/auth/signin"} replace />;
};
const Dashboard = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getProfile());
	}, [dispatch]);

	const [nav, showNav] = useState(false);
	const [menu, showMenu] = useState(false);
	const [logoutModal, showLogoutModal] = useState(false);
	const toggleNav = () => {
		showNav(!nav);
	};
	const toggleMenu = () => {
		showMenu(!menu);
	};

	const signOut = () => {
		showLogoutModal(true);
	};

	return (
		<>
			<Topbar
				toggleNav={toggleNav}
				nav={nav}
				signOut={signOut}
				toggleMenu={toggleMenu}
				menu={menu}
			/>
			<Alert />
			<div className="app__content">
				<div className={`menu ${menu && "active"}`}>
					<Sidebar signOut={signOut} />
				</div>

				<div className="main__content" onClick={() => showMenu(false)}>
					<Outlet />
					<AnimatePresence exitBeforeEnter>
						{logoutModal && <SignOut showLogoutModal={showLogoutModal} />}
					</AnimatePresence>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
