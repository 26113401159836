import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Btn } from "../../Components/UI/Buttons";
import { modalVariants } from "../../Helpers/animation";
import { updateCategory } from "../../Redux/courseSlice";
import Uploader from "../../Components/Uploader/Uploader";
const EditCategory = ({ closeModal, category }) => {
	const [details, setDetails] = useState({ id: category?._id });
	const { status } = useSelector((state) => state.courses);
	const dispatch = useDispatch();
	const handleChange = (e) => {
		if (e.target.name === "name") {
			setDetails({
				...details,

				[e.target.name]: e.target.value.trim(),
				group: e.target.value.trim(),
			});
			return;
		}

		setDetails({ ...details, [e.target.name]: e.target.value.trim() });
	};

	const handleSubmit = () => {
		if (!details) {
			return;
		}

		dispatch(updateCategory(details));
	};

	const handleImage = (image) => {
		setDetails({ ...details, image });
	};

	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal course__modal category__modal">
				<h2 className="heading-sec modal__heading heading"> Add Category</h2>
				<div className="modal__content">
					<div className="modal__input__group">
						<label htmlFor="price">name</label>
						<input
							type="text"
							name="name"
							id="name"
							defaultValue={category?.name}
							placeholder="new category"
							onChange={handleChange}
						/>
					</div>
					<div className="modal__input__group">
						<label htmlFor="price">description</label>
						<input
							type="text"
							name="description"
							id="description"
							defaultValue={category?.description}
							placeholder="description"
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="category__uploader">
					<Uploader
						handleUpload={handleImage}
						image={{ name: category?.image }}>
						featured Image
					</Uploader>
				</div>

				<div className="modal__footer">
					<button
						className="btn btn-cancel"
						onClick={(e) => {
							closeModal();
						}}>
						Cancel
					</button>
					<Btn handleClick={handleSubmit} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default EditCategory;
