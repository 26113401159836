import React, { useEffect, useState } from "react";
import "./Landing.scss";
import { BtnLoader } from "../../Components/UI/Loader";

import { useDispatch, useSelector } from "react-redux";
import { getLanding, setLanding } from "../../Redux/landingSlice";
import { imageFallback } from "../../Helpers/others";
const Landing = () => {
	const dispatch = useDispatch();
	const { status } = useSelector((state) => state.landing);
	const { data: landing } = useSelector((state) => state.landing.details);
	const [details, setDetails] = useState({});
	const [preview, setPreview] = useState({});
	useEffect(() => {
		dispatch(getLanding());
	}, [dispatch]);
	const handleUpload = (e) => {
		const file = e.target.files[0];
		setDetails({
			...details,
			[e.target.name]: file,
		});

		const reader = new FileReader();

		reader.onloadend = function () {
			setPreview({
				...preview,
				[e.target.name]: reader.result,
			});
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleChange = (e) => {
		const { id, value } = e.target;

		setDetails({
			...details,
			[id]: value,
		});
	};
	const handleSubmit = (e) => {
		if (details) {
			dispatch(setLanding(details));
		}
	};

	return (
		<div className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri">Landing page</h2>
				</div>
			</header>
			<main className="landing main">
				<div className="banner">
					<div className="heading banner__heading">Featured Hero</div>
					<div className="card__container">
						<div className="card">
							<h2 className="heading-sec heading card__heading">Headline</h2>
							<textarea
								className="card__text"
								onChange={handleChange}
								id="heading"
								defaultValue={landing?.heading}></textarea>
						</div>
						<div className="card">
							<h2 className="heading-sec heading card__heading">Sub Heading</h2>
							<textarea
								className="card__text"
								id="subheading"
								onChange={handleChange}
								defaultValue={landing?.subheading}></textarea>
						</div>
						<div className="card">
							<h2 className="heading-sec heading card__heading">Button Text</h2>
							<textarea
								className="card__text"
								id="buttonText"
								onChange={handleChange}
								defaultValue={landing?.buttonText}></textarea>
						</div>
						<div className="card">
							<h2 className="heading-sec heading card__heading">Button Link</h2>
							<textarea
								id="buttonLink"
								className="card__text"
								onChange={handleChange}
								defaultValue={landing?.buttonLink}></textarea>
						</div>
						<div className="card">
							<h2 className="heading-sec heading card__heading">Image</h2>
							<div className="card__media__container">
								<div className="card__media">
									<img
										src={preview?.image ?? landing?.image ?? ""}
										alt="landing"
										onError={imageFallback}
										className="card__media__item"
									/>
								</div>
								<div className="card__media__options">
									<h3 className="heading card__media__title">
										{details?.image?.name ?? "image.jpeg"}
									</h3>
									<div className="card__media__actions">
										{/* <button className="btn card__media__action__link">
											crop
										</button> */}

										<div className="file__container">
											<input
												type="file"
												id="landing-image"
												name="image"
												className="file"
												onChange={handleUpload}
												accept=".jpg,.jpeg,.png"
											/>
											<label
												htmlFor="landing-image"
												className="btn card__media__action__link">
												replace
											</label>
										</div>

										<button
											className="btn card__media__action__link"
											onClick={() => {
												setPreview({
													...preview,
													image: "",
												});

												setDetails({
													...preview,
													image: "",
												});
											}}>
											remove
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="card">
							<h2 className="heading-sec heading card__heading">
								{details?.video?.name ?? "Video.mp4"}
							</h2>
							<div className="card__media__container">
								<div className="card__media">
									<video
										src={preview?.video ?? landing?.video ?? ""}
										alt="landing"
										onError={imageFallback}
										className="card__media__item"
									/>
								</div>
								<div className="card__media__options">
									<h3 className="heading card__media__title">video.mp4</h3>
									<div className="card__media__actions">
										<div className="file__container">
											<input
												type="file"
												id="landing-video"
												className="file"
												name="video"
												onChange={handleUpload}
												accept=".mp4"
											/>
											<label
												htmlFor="landing-video"
												className="btn card__media__action__link">
												replace
											</label>
										</div>
										<button
											className="btn card__media__action__link"
											onClick={() => {
												setPreview({
													...preview,
													video: "",
												});
												setDetails({
													...details,
													video: "",
												});
											}}>
											remove
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="banner">
					<div className="heading banner__heading">Impact statistics</div>
					<div className="card__container">
						<div className="card">
							<input
								type="number"
								name="number"
								id="registered"
								defaultValue={landing?.registered}
								className="heading-sec heading card__heading"
								onChange={handleChange}
							/>

							<p className="card__text">registered students</p>
						</div>
						<div className="card">
							<input
								type="number"
								name="number"
								id="helped"
								className="heading-sec heading card__heading"
								defaultValue={landing?.helped}
								onChange={handleChange}
							/>

							<p className="card__text">
								students have been helped to achieve their dreams
							</p>
						</div>
						<div className="card">
							<input
								type="number"
								name="number"
								id="rank"
								className="heading-sec heading card__heading"
								defaultValue={landing?.rank}
								onChange={handleChange}
							/>

							<p className="card__text">
								ranked among the top {landing?.rank ?? 10} growing online
								learning startups in west africa
							</p>
						</div>
						<div className="card">
							<input
								type="number"
								name="number"
								id="visits"
								defaultValue={landing?.visits}
								className="heading-sec heading card__heading"
								onChange={handleChange}
							/>

							<p className="card__text">
								More than {landing?.visits ?? 0} people visit our site monthly
							</p>
						</div>
					</div>
					<div className="banner__btn">
						<button
							className="btn btn-pri btn-load"
							style={{ width: "18rem" }}
							onClick={() => {
								handleSubmit();
							}}>
							{status === "loading" ? <BtnLoader /> : "Save Changes"}
						</button>
					</div>
				</div>
			</main>
		</div>
	);
};

export default Landing;
