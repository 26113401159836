import React, { useEffect, useState } from "react";
import "./Orientation.scss";
import { EditBtn, DeleteBtn } from "../../Components/UI/Buttons";
import { VideoIcon } from "../../Components/UI/Icons";
import { AddBtn } from "../../Components/UI/Buttons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EditOrientation from "./EditOrientation";
import AddOrientation from "./AddOrientation";
import { deleteVideo, getOrientations } from "../../Redux/orientationSlice";
import Loader from "../../Components/UI/Loader";
import { containerVariants, courseVariants } from "../../Helpers/animation";
import { AnimatePresence, motion } from "framer-motion";
const Orientation = () => {
	const { all: orientations, status } = useSelector(
		(state) => state.orientation
	);

	const [edit, setEdit] = useState(false);
	const [addModal, showAddModal] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getOrientations());
	}, [dispatch]);

	const removeOrientation = (id) => {
		dispatch(deleteVideo(id));
	};
	const closeModal = () => {
		showAddModal(false);
		setEdit(false);
	};

	useEffect(() => {
		if (status === "success") {
			closeModal();
		}
	}, [status]);

	return orientations?.length < 1 && status === "loading" ? (
		<Loader />
	) : (
		<div className="container">
			<AnimatePresence exitBeforeEnter>
				{edit && <EditOrientation closeModal={closeModal} orientation={edit} />}
				{addModal && <AddOrientation closeModal={closeModal} />}
			</AnimatePresence>
			<header className="header">
				<div className="header">
					<div className="header__content">
						<h2 className="heading-pri">Orientation</h2>
						<AddBtn handleClick={() => showAddModal(true)} />
					</div>
				</div>
			</header>

			<motion.main
				variants={containerVariants}
				initial="start"
				animate="end"
				className="main orientation__container">
				<AnimatePresence exitBeforeEnter>
					{orientations?.map((orientation, index) => (
						<motion.div
							variants={courseVariants}
							className="orientation"
							key={orientation["_id"]}>
							<div className="orientation__image">
								<VideoIcon className={"orientation__img"} />
							</div>
							<h3 className="orientation__title heading ">
								<span>
									{" "}
									{index > 0
										? (index / 10).toLocaleString("en-US", {
												minimumIntegerDigits: 1,
												useGrouping: true,
										  })
										: "0.0"}
								</span>
								&nbsp;
							</h3>
							<p className="orientation__description">{orientation?.title}</p>
							<span className="orientation__duration">
								{orientation?.length}
							</span>
							<div className="orientation__action">
								<EditBtn
									handleClick={() => {
										setEdit(orientation);
									}}
								/>
								<DeleteBtn
									handleClick={() => {
										removeOrientation(orientation["_id"]);
									}}
								/>
							</div>
						</motion.div>
					))}
				</AnimatePresence>
			</motion.main>
		</div>
	);
};

export default Orientation;
