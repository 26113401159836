import React, { useEffect, useState } from "react";
import "./Topbar.scss";
import logo from "../../Assets/desktop-logo.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProfile } from "../../Redux/authSlice";
import { imageFallback } from "../../Helpers/others";
import defaultImg from "../../Assets/default.png";
import { Arrow, DropdownIcon } from "../UI/Icons";
import EditProfile from "./EditProfile";
import { AnimatePresence } from "framer-motion";
const Topbar = ({ toggleNav, nav, signOut, toggleMenu, menu }) => {
	const { user: admin, status } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [open, isOpen] = useState(false);
	const [update, showUpdate] = useState(false);

	useEffect(() => {
		if (!admin) {
			dispatch(getProfile());
		}
	}, [dispatch, admin]);

	const toggleOpen = () => isOpen(!open);

	const closeModal = () => {
		// showChangePassword(false);
		showUpdate(false);
	};
	useEffect(() => {
		closeModal();
	}, [status]);

	return (
		<>
			<AnimatePresence exitBeforeEnter>
				{update && <EditProfile closeModal={closeModal} admin={admin} />}
			</AnimatePresence>
			<div className="topbar">
				<div
					onClick={toggleMenu}
					className={`btn topbar__menu ${menu ? "active" : ""}`}>
					<Arrow />
				</div>
				<div className="topbar__logo">
					<img src={logo} alt="logo" className="topbar__logo__img" />
					<button
						className={`nav__btn ${nav ? "active" : ""}`}
						onClick={toggleNav}></button>
				</div>
				<div className="topbar__other">
					<div className="topbar__user">
						<div className="topbar__user__notification"></div>
						<div className="topbar__user__image">
							{admin?.profileImg ? (
								<img
									src={admin?.profileImg}
									className="topbar__img"
									onError={imageFallback}
									alt="admin user"
								/>
							) : (
								<img
									src={defaultImg}
									className="topbar__img"
									onError={imageFallback}
									alt="admin user"
								/>
							)}
						</div>
						<div className="topbar__user__details">
							<h2 className="heading heading-sec topbar__user__name">
								{admin?.firstName ?? "Admin"}&nbsp; {admin?.lastName ?? ""}
							</h2>
							<h4 className="heading heading-ter topbar__user__role">
								{admin?.title ?? "admin"}
							</h4>
						</div>
						<button
							className={`btn btn-more topbar__btn select__header__icon ${
								open && "active"
							}`}
							onClick={toggleOpen}>
							<DropdownIcon />
						</button>
					</div>
					{open && (
						<div className="topbar__list__container">
							<ul className="topbar__list select__list">
								<li
									className="topbar__item select__list__item"
									onClick={() => showUpdate(true)}>
									Update Profile
								</li>

								{/* <li
									className="topbar__item select__list__item"
									onClick={() => showChangePassword(true)}>
									Update Password
								</li>
 */}
								<li
									className="topbar__item select__list__item"
									onClick={signOut}>
									Sign out
								</li>
							</ul>
						</div>
					)}{" "}
				</div>
			</div>
		</>
	);
};

export default Topbar;
