import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import { getFileHeader } from "../Helpers/header";
import { handleSuccess, handleErrors } from "../Helpers/others";

export const getLanding = createAsyncThunk(
	"landing/getLanding",
	async (_, { dispatch }) => {
		return axios
			.get(`${config.baseUrl}/admin/landing`, { headers: getFileHeader() })
			.then((res) => res.data)
			.then((data) => {
				return data;
			})

			.catch((err) =>
				handleErrors({ err, msg: `failed to load landing page ` }, dispatch)
			);
	}
);

export const setLanding = createAsyncThunk(
	"landing/setLanding",

	async (details, { dispatch }) => {
		const formData = new FormData();
		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}
		return axios
			.post(`${config.baseUrl}/admin/landing`, formData, {
				headers: getFileHeader(),
			})
			.then((res) => {
				handleSuccess("successfully updated landing page", dispatch);
				return res.data;
			})
			.then(() => dispatch(getLanding()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to update landing page` }, dispatch)
			);
	}
);

export const landingSlice = createSlice({
	name: "landing",
	initialState: { details: {}, status: null },

	extraReducers: {
		[getLanding.pending]: (state, action) => {
			state.status = "loading";
		},
		[getLanding.fulfilled]: (state, { payload }) => {
			state.details = payload;
			state.status = "success";
		},
		[getLanding.rejected]: (state, action) => {
			state.status = "failed";
		},
		[setLanding.pending]: (state, action) => {
			state.status = "loading";
		},
		[setLanding.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[setLanding.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default landingSlice.reducer;
