import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Uploader from "../../Components/Uploader/Uploader";
import { ViewBtn } from "../../Components/UI/Buttons";
import { useSelector, useDispatch } from "react-redux";
import { updatePost, getSinglePost } from "../../Redux/blogSlice";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Components/UI/Loader";
import Tags from "../../Components/Tags/Tags";
import config from "../../Config.json";

const EditPost = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { current: post, status } = useSelector((state) => state.blog);
	const [update, setUpdate] = useState({});

	const [tags, setTags] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSinglePost(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (post) {
			setTags(post?.tags?.split(","));
		}
	}, [post]);

	useEffect(() => {
		setUpdate({ title: post.title });
	}, [status, post]);

	const handleChange = (e) => {
		e.preventDefault();
		setUpdate({
			...update,
			[e.target.name]: String(e.target.value).trim(),
		});
	};
	const handleImage = (image) => {
	
		setUpdate({
			...update,
			image: image,
		});
	};

	const handleSubmit = () => {
		dispatch(updatePost({ details: { ...update, tags: tags?.join(",") }, id }));
	};

	const back = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	useEffect(() => {
	
		if (post?.status === true) {
			back();
		}
	}, [post, back]);

	return status === "loading" ? (
		<Loader />
	) : (
		<section className="container">
			<header className="header">
				<div className="header">
					<h2 className="heading-pri link" onClick={back}>
						back
					</h2>
				</div>

				<div className="header__content">
					<ViewBtn />
					<button className="btn btn-pri uploader__btn" onClick={handleSubmit}>
						save blog post
					</button>
				</div>
			</header>
			<main className="main blog__add__container">
				<div className="banner blog__add">
					<div className="blog__add__content">
						<h4 className="heading blog__add__heading">Title</h4>
						<input
							type="text"
							name="title"
							id="title"
							className="blog__add__title"
							placeholder="insert title"
							defaultValue={post?.title}
							onChange={handleChange}
						/>
					</div>
					<div className="blog__add__content">
						<h4 className="heading blog__add__heading">Excerpt</h4>
						<input
							type="text"
							name="excerpt"
							className="blog__add__excerpt"
							id="excerpt"
							rows={7}
							placeholder="Add a summary of the post to appear on your home page or blog"
							defaultValue={post?.excerpt}
							onChange={handleChange}
						/>
					</div>
					<div className="blog__add__content">
						<h4 className="heading blog__add__heading">Contents</h4>
						<CKEditor
							className="blog__add__text"
							editor={ClassicEditor}
							data={post?.content ?? ""}
							rows="8"
							onReady={(editor) => {}}
							config={{
								ckfinder: {
									// The URL that the images are uploaded to.
									uploadUrl: `${config.baseUrl}/v1/file/upload/${config.token}?ckeditor=true`,
								},
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								setUpdate({ ...update, content: data.trim() });
							
							}}
						/>
					</div>
				</div>
				<div className="banner blog__add">
					<div className="blog__add__content">
						<Uploader handleUpload={handleImage}>featured Image</Uploader>
					</div>
				</div>
				<div className="banner blog__add">
					<div className="blog__add__content">
						<div className="blog__add__content">
							<h4 className="heading blog__add__heading">author</h4>

							<input
								type="text"
								name="author"
								id="author"
								className="blog__add__author"
								placeholder="author"
								defaultValue={post?.author}
								onChange={handleChange}
							/>
						</div>
						<div className="blog__add__content">
							<h4 className="heading blog__add__heading">tags</h4>
							<Tags tags={tags} setTags={setTags} />
						</div>
					</div>
				</div>
				<div className="banner__btn blog__add__btn">
					<button className="btn btn-pri uploader__btn" onClick={handleSubmit}>
						save
					</button>
				</div>
			</main>
		</section>
	);
};

export default EditPost;
