import React from "react";
import "./Search.scss";
import { SearchIcon } from "./Icons";
const Search = ({ handleSearch, children }) => {
	const search = (e) => {
		const value = e.target.value.trim();

		handleSearch(value);
	};
	return (
		<div className={`search__container ${children ?? ""}`}>
			<SearchIcon />
			<input
				type="search"
				name="search"
				id="search"
				className="search"
				placeholder={`search ${children ?? ""}`}
				onChange={search}
			/>
		</div>
	);
};

export default Search;
