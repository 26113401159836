import React, { useEffect, useState } from 'react'
import './Cohort.css'
import { getCohort, setStatus, setCohort } from "../../Redux/cohortSlice";
import { useDispatch, useSelector } from "react-redux";

function Cohort() {
    const dispatch = useDispatch();
    const { all: cohort, status } = useSelector((state) => state.cohort);
    const [currentCohort, setCurrentCohort] = useState()
    useEffect(() => {
        dispatch(getCohort());
    }, [dispatch]);
    return (
        <div>
            <div className='cohort--con'>
                <div className='cohort'>
                    <div className='cohort__heading'>
                        <h4>Cohort Details</h4>
                    </div>
                    <div className='cohort__body'>
                        <form onSubmit={(e) => { e.preventDefault(); dispatch(setCohort({ cohort: currentCohort })) }}>
                            <div>
                                <label>Current Cohort : </label>
                                <input defaultValue={cohort?.current} onChange={(e) => setCurrentCohort(e.target.value)} placeholder="loading cohort..." />
                                <button type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cohort