import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Btn } from "../../Components/UI/Buttons";
import Uploader from "../../Components/Uploader/Uploader";
import { addTestimonial } from "../../Redux/testimonialSlice";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";
import { useSelector } from "react-redux";

const AddTestimonial = ({ closeModal }) => {
	const [details, setDetails] = useState();

	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};
	const { status } = useSelector((state) => state.testimonials);

	const dispatch = useDispatch();
	const postTestimonial = () => {
		if (details?.course && details?.name && details?.testimonial) {
			dispatch(addTestimonial(details));
			return;
		}
		
	};

	const handleUpload = (upload) => {
		setDetails({
			...details,
			image: upload,
		});
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container  testimonial__modal">
			<div className="modal ">
				<h2 className="modal__heading heading-sec heading">Add testimonial</h2>
				<div className="modal__content">
					<div className="modal__content__left">
						<div className="modal__input__group__container ">
							<div className="modal__input__group">
								<label htmlFor="name">Name</label>
								<input
									type="text"
									onChange={handleChange}
									name="name"
									id="name"
								/>
							</div>
							<div className="modal__input__group">
								<label htmlFor="code">Course</label>
								<input
									type="text"
									onChange={handleChange}
									name="course"
									id="course"
								/>
							</div>
						</div>
						<div className="modal__input__group__container">
							<div className="modal__input__group modal__input__group--fill">
								<label htmlFor="discount"> texts </label>
								<textarea
									name="testimonial"
									id="testimonial"
									cols="30"
									onChange={handleChange}
									rows="7"></textarea>
							</div>
						</div>
					</div>

					<div className="modal__upload__group">
						<Uploader
							text={"upload image"}
							handleUpload={handleUpload}
							fileType={".jpg,.png,.jpeg"}></Uploader>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={postTestimonial} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default AddTestimonial;
