import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader from "../Helpers/header";
import { handleErrors, handleSuccess } from "../Helpers/others";

export const getInstructors = createAsyncThunk(
	"instructors/getInstructors",
	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/instructor/all`, options)
			.then((res) => res.data)
			.then(({ data }) => {
				return data.tutors;
			})
			.catch((err) =>
				handleErrors({ err, msg: `failed to load instructors` }, dispatch)
			);
	}
);

export const enableInstructor = createAsyncThunk(
	"instructors/enableInstructor",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(
				`${config.baseUrl}/admin/instructor/enable`,
				{
					id,
				},
				options
			)
			.then((res) => {
				handleSuccess("successfully enabled instructor", dispatch);
				return res.data;
			})
			.then(() => dispatch(getInstructors()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to enable instructor` }, dispatch)
			);
	}
);

export const createInstructor = createAsyncThunk(
	"instructors/createInstructor",
	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.post(`${config.baseUrl}/admin/instructor/signup`, details, options)
			.then((res) => {
				handleSuccess("successfully created instructor", dispatch);
				return res.data;
			})
			.then(() => dispatch(getInstructors()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to create instructor` }, dispatch)
			);
	}
);

export const disableInstructor = createAsyncThunk(
	"instructors/disableInstructor",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(
				`${config.baseUrl}/admin/instructor/disable`,
				{
					id: id,
				},
				options
			)
			.then((res) => {
				handleSuccess("successfully disabled instructor", dispatch);

				return res.data;
			})
			.then(() => dispatch(getInstructors()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to disable instructor` }, dispatch)
			);
	}
);

export const instructorSlice = createSlice({
	name: "instructors",
	initialState: { all: [], status: null },

	extraReducers: {
		[getInstructors.pending]: (state, action) => {
			state.status = "loading";
		},
		[getInstructors.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getInstructors.rejected]: (state, action) => {
			state.status = "failed";
		},
		[enableInstructor.pending]: (state, action) => {
			state.status = "loading";
		},
		[enableInstructor.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[enableInstructor.rejected]: (state, action) => {
			state.status = "failed";
		},
		[disableInstructor.pending]: (state, action) => {
			state.status = "loading";
		},
		[disableInstructor.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[disableInstructor.rejected]: (state, action) => {
			state.status = "failed";
		},
		[createInstructor.pending]: (state, action) => {
			state.status = "loading";
		},
		[createInstructor.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[createInstructor.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default instructorSlice.reducer;
