import { motion } from "framer-motion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BtnLoader } from "../../Components/UI/Loader";
import { getOtp } from "../../Redux/authSlice";
import { formVariants } from "../../Helpers/animation";
import Newpassword from "./NewPassword";
const PasswordReset = () => {
	const [email, setEmail] = useState(null);
	const [errors, setErrors] = useState(null);
	const dispatch = useDispatch();
	const { otp, status } = useSelector((state) => state.auth);
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!email) {
			setErrors("Please check your email");
			return;
		}
		dispatch(getOtp(email));
	};

	const handleChange = (e) => {
		if (e.target.value.length > 3) {
			setEmail({ email: e.target.value.trim().toLowerCase() });
		} else {
			setErrors(`please check your email`);
		}
	};
	return otp ? (
		<Newpassword />
	) : (
		<motion.div
			variants={formVariants}
			initial="start"
			animate="end"
			exit="exit">
			<form className="signin__form" onSubmit={handleSubmit}>
				<div className="signin__form__heading">
					<h1 className="heading heading-pri form__heading">Hi there,</h1>
					<h2
						className="heading heading-sec 
								form__heading--2">
						Please enter your email
					</h2>
				</div>
				<div className="form__group">
					<label htmlFor="password">email</label>
					<input
						type="email"
						id="email"
						name="email"
						placeholder="example@gmail.com"
						className="signin__input"
						onChange={handleChange}
					/>
					<div className="signin__extra">
						<Link to="/auth/signin" className="btn btn-del form__link">
							remember password?
						</Link>
					</div>
				</div>
				<div className="form__group form__submit__container">
					<button type="submit" className="form__submit btn btn-pri ">
						{status === "loading" ? <BtnLoader /> : "Continue"}
					</button>
				</div>
			</form>

			{errors && <div className="form__error__container">{errors ?? ""}</div>}
		</motion.div>
	);
};

export default PasswordReset;
