import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { editProfile } from "../../Redux/authSlice";
import { Btn } from "../UI/Buttons";
import { modalVariants } from "../../Helpers/animation";
import { motion } from "framer-motion";
const Editprofile = ({ closeModal, admin }) => {
	const [details, setDetails] = useState(null);

	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};
	const { status } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	const updateDetails = () => {
		if (details) {
			dispatch(editProfile(details));
			return;
		}
		
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal instructor__modal">
				<h2 className="modal__heading heading-sec heading">Update Profile</h2>
				<div className="modal__content instructor__modal__content">
					<div className="modal__input__group__container">
						<div className="modal__input__group">
							<label htmlFor="name">First name</label>
							<input
								type="text"
								onChange={handleChange}
								name="firstName"
								id="firstname"
								defaultValue={admin?.firstName}
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="code">Last name</label>
							<input
								type="text"
								onChange={handleChange}
								name="lastName"
								id="lastname"
								defaultValue={admin?.lastName}
							/>
						</div>
					</div>
					<div className="modal__input__group modal__input__group--2">
						<label htmlFor="discount"> Title</label>
						<input
							type="title"
							onChange={handleChange}
							name="title"
							id="title"
							className="input--2"
							defaultValue={admin?.title}
						/>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={updateDetails} status={status}>
						{"Submit"}
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default Editprofile;
