import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader from "../Helpers/header";
import { handleErrors } from "../Helpers/others";

export const getUsers = createAsyncThunk(
	"users/getUsers",
	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/student/all`, options)
			.then((res) => res.data)
			.then(({ data }) => {
				return data;
			})
			.catch((err) =>
				handleErrors({ err, msg: `failed to load users` }, dispatch)
			);
	}
);

export const userSlice = createSlice({
	name: "users",
	initialState: { all: [], status: null },

	extraReducers: {
		[getUsers.pending]: (state, action) => {
			state.status = "loading";
		},
		[getUsers.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getUsers.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default userSlice.reducer;
