import React from "react";
import animationData from "../../Assets/PageLoaders.json";
import spinner from "../../Assets/DualRing.svg";
import spinner2 from "../../Assets/DualRing2.svg";
import Lottie from "react-lottie-player";
import "./Loader.scss";
import { motion } from "framer-motion";
import { loaderVariants } from "../../Helpers/animation";

const Loader = () => {
	return (
		<motion.div
			variants={loaderVariants}
			initial={"start"}
			animate={"end"}
			exit={"exit"}
			className="loading__container">
			<Lottie
				animationData={animationData}
				loop
				className="loading"
				play></Lottie>
		</motion.div>
	);
};

export const BtnLoader = () => {
	return (
		<motion.div
			variants={loaderVariants}
			initial={"start"}
			animate={"end"}
			exit={"exit"}
			className="btn__loading__container">
			<img src={spinner} alt="loading button" className="btn__loading" />
		</motion.div>
	);
};

export const BtnLoader2 = () => {
	return (
		<motion.div
			variants={loaderVariants}
			initial={"start"}
			animate={"end"}
			exit={"exit"}
			className="btn__loading__container">
			<img src={spinner2} alt="loading button" className="btn__loading" />
		</motion.div>
	);
};

export default Loader;
