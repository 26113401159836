import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { adminSignOut } from "../../Redux/authSlice";
import { modalVariants } from "../../Helpers/animation";
import { motion } from "framer-motion";
const SignOut = ({ showLogoutModal }) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const logout = () => {
		dispatch(adminSignOut());
		navigate("/auth/signin");
	};
	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal signout__modal">
				<h2 className="signout__heading">Are you sure you want to Sign out?</h2>
				<div className="signout__modal__btn">
					<button className="form__submit btn btn-pri mr-1" onClick={logout}>
						Yes
					</button>
					<button
						className=" form__submit btn btn-no "
						onClick={() => {
							showLogoutModal(false);
						}}>
						No
					</button>
				</div>
			</div>
		</motion.div>
	);
};

export default SignOut;
