import React from "react";
import "./Buttons.scss";
import { EditIcon, DeleteIcon, PlusIcon, Eye } from "./Icons";
import { BtnLoader } from "./Loader";
export const EditBtn = ({ handleClick }) => {
	return (
		<button className="btn btn-ter" onClick={handleClick}>
			<EditIcon />
			<span>Edit</span>
		</button>
	);
};
export const DeleteBtn = ({ handleClick, children, status }) => {
	return (
		<button className="btn btn-ter btn-del" onClick={handleClick}>
			{status === "loading" ? (
				<BtnLoader />
			) : (
				<>
					<DeleteIcon /> <span>{children ?? ""}</span>
				</>
			)}
		</button>
	);
};

export const AddBtn = ({ handleClick, children }) => {
	return (
		<button className="btn btn-ter btn-del" onClick={handleClick}>
			<PlusIcon /> <span>{children ?? ""}</span>
		</button>
	);
};

export const Btn = ({ handleClick, children, status }) => {
	return (
		<button
			className="btn btn-pri"
			onClick={() => {
				handleClick();
			}}>
			{status === "loading" ? <BtnLoader /> : children ?? "Save Changes"}
		</button>
	);
};
export const ViewBtn = ({ handleClick, children }) => {
	return (
		<button className="btn btn-ter btn-view" onClick={handleClick}>
			<div className="btn-view--icon">
				<Eye />
			</div>
			{children ?? "view"}
		</button>
	);
};

