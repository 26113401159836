import React, { useState } from "react";
import { Btn } from "../../Components/UI/Buttons";
import Uploader from "../../Components/Uploader/Uploader";
import { useDispatch } from "react-redux";
import { mailSubscribers } from "../../Redux/subscriberSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";
const SubscriberModal = ({ closeModal }) => {
	const [details, setDetails] = useState(null);
	const dispatch = useDispatch();
	const sendMail = () => {
		if (details) {
			dispatch(mailSubscribers(details));
		}
	};
	const { status } = useSelector((state) => state.subscribers);
	const handleUpload = (upload) => {
		setDetails({ ...details, file: upload });
	};
	const handleChange = (e) => {
		setDetails({ ...details, [e.target.name]: e.target.value.trim() });
	};

	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal subscriber__modal">
				<div className="modal__content">
					<div className="modal__input__group">
						<input
							type="text"
							name="subject"
							id="subject"
							placeholder="subject"
							onChange={handleChange}
						/>
					</div>
					<div className="modal__input__group">
						<textarea
							name="message"
							id="message"
							placeholder="body"
							rows={8}
							onChange={handleChange}></textarea>
					</div>
					<div className="modal__upload__group">
						<Uploader
							handleUpload={handleUpload}
							fileType={".mp4,.png,.jpg,.jpeg"}></Uploader>
					</div>
					<div className="modal__footer">
						<button className="btn btn-cancel" onClick={closeModal}>
							Cancel
						</button>
						<Btn handleClick={sendMail} status={status}>
							Send
						</Btn>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default SubscriberModal;
