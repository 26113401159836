import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader, { getFileHeader } from "../Helpers/header";
import { handleSuccess, handleErrors } from "../Helpers/others";


export const getOrientations = createAsyncThunk(
	"orientation/getOrientations",
	async (_, { dispatch }) => {
		return axios
			.get(`${config.baseUrl}/admin/landing`, { headers: getHeader() })
			.then((res) => res.data)
			.then((data) => {
				return data?.data?.orientation;
			})

			.catch((err) =>
				handleErrors(
					{ err, msg: `failed to load orientation videos ` },
					dispatch
				)
			);
	}
);

export const getUrl = async (file, dispatch) => {
	const formData = new FormData();
	formData.append("file", file);

	return axios
		.post(`${config.baseUrl}/v1/file/upload/${config.token}`, formData, {
			headers: getFileHeader(),
		})
		.then(({ data }) => {
			return data.data;
		});
};

export const uploadHonor = createAsyncThunk(
	"orientation/uploadHonor",

	async (honor, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return getUrl(honor, dispatch)
			.then( (link) => {
				axios.post(
					`${config.baseUrl}/admin/orientation/honor`,
					{ honor: String(link[0].url) },

					options
				);
			})
			.then(() => {
				return handleSuccess("successfully uploaded honor", dispatch);
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to upload honor`,
					},
					dispatch
				)
			);
	}
);

export const uploadVideo = createAsyncThunk(
	"orientation/uploadVideo",

	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return getUrl(details.video, dispatch)
			.then((video) => {
				console.log(video);
				return axios.post(
					`${config.baseUrl}/admin/orientation/`,
					{
						...details,
						video: String(video[0].url),
					},
					options
				);
			})
			.then((res) => {
				handleSuccess("successfully uploaded video", dispatch);
				dispatch(getOrientations());
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to upload video`,
					},
					dispatch
				)
			);
	}
);

export const editVideo = createAsyncThunk(
	"orientation/editVideo",

	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		if (details.video) {
			return getUrl(details.video)
				.then((video) =>
					axios.patch(
						`${config.baseUrl}/admin/orientation/`,
						{ ...details, video: video[0].url },
						options
					)
				)
				.then((res) => {
					handleSuccess("successfully updated video", dispatch);
					dispatch(getOrientations());
				})
				.catch((err) =>
					handleErrors(
						{
							err,
							msg: `failed to update video`,
						},
						dispatch
					)
				);
		} else {
			return axios
				.patch(`${config.baseUrl}/admin/orientation/`, details, options)

				.then((res) => {
					handleSuccess("successfully updated video", dispatch);
					dispatch(getOrientations());
				})
				.catch((err) =>
					handleErrors(
						{
							err,
							msg: `failed to update video	`,
						},
						dispatch
					)
				);
		}
	}
);

export const deleteVideo = createAsyncThunk(
	"orientation/deleteVideo",

	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.delete(
				`${config.baseUrl}/admin/orientation/${id}`,

				options
			)
			.then((res) => {
				handleSuccess("successfully deleted video", dispatch);
				dispatch(getOrientations());
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to delete video`,
					},
					dispatch
				)
			);
	}
);

export const orientationSlice = createSlice({
	name: "orientation",
	initialState: { all: [], status: null, honor: {} },

	extraReducers: {
		[getOrientations.pending]: (state, action) => {
			state.status = "loading";
		},
		[getOrientations.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getOrientations.rejected]: (state, action) => {
			state.status = "failed";
		},
		[uploadHonor.pending]: (state, action) => {
			state.status = "loading";
		},
		[uploadHonor.fulfilled]: (state, { payload }) => {
			state.honor = payload;
			state.status = "success";
		},
		[uploadHonor.rejected]: (state, action) => {
			state.status = "failed";
		},
		[editVideo.pending]: (state, action) => {
			state.status = "loading";
		},
		[editVideo.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[editVideo.rejected]: (state, action) => {
			state.status = "failed";
		},
		[uploadVideo.pending]: (state, action) => {
			state.status = "loading";
		},
		[uploadVideo.fulfilled]: (state, { payload }) => {
			state.honor = payload;
			state.status = "success";
		},
		[uploadVideo.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deleteVideo.pending]: (state, action) => {
			state.status = "loading";
		},
		[deleteVideo.fulfilled]: (state, { payload }) => {
			state.honor = payload;
			state.status = "success";
		},
		[deleteVideo.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default orientationSlice.reducer;
