import React, { useState, useEffect } from "react";
import "./SignIn.scss";
import { useDispatch } from "react-redux";
import { adminSignin, authErrorClear } from "../../Redux/authSlice";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BtnLoader } from "../../Components/UI/Loader";
import { motion } from "framer-motion";
import { formVariants } from "../../Helpers/animation";


const Signin = () => {
	const [details, setDetails] = useState(null);
	const [errors, setErrors] = useState(null);
	const [loading, setLoading] = useState(false);

	const { error, status } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(authErrorClear());

		if (!errors) {
			setLoading(true);
			return dispatch(adminSignin(details));
		}
		if (!details?.email) {
			setErrors("Please check your email");
		} else if (!details?.password) {
			setErrors("Please check your password");
		}
	};

	const handleChange = (e) => {
		setErrors(null);

		if (e.target.value.length > 3) {
			setDetails({ ...details, [e.target.id]: e.target.value.trim() });
		} else {
			setErrors(`please check ${e.target.id}`);
		}
	};
	useEffect(() => {
		if (status === "success") {
			setLoading(false);
		}
	}, [status]);

	useEffect(() => {
		setErrors(error?.message);
		return () => {
			setErrors(null);
			if (status === "failed") {
				dispatch(authErrorClear());
			}
		};
	}, [error, setErrors, status, dispatch]);

	return (
		<motion.div
			variants={formVariants}
			initial="start"
			animate="end"
			exit="exit"
			layout>
			<form
				className="signin__form"
				key={"signin_form"}
				onSubmit={handleSubmit}>
				<div className="signin__form__heading">
					<h1 className="heading heading-pri form__heading">Hi there,</h1>
					<h2
						className="heading heading-sec 
								form__heading--2">
						Sign in to access your account
					</h2>
				</div>
				<div className="form__group">
					<label htmlFor="password">email</label>
					<input
						type="email"
						id="email"
						name="email"
						placeholder="example@gmail.com"
						className="signin__input"
						onChange={handleChange}
					/>
				</div>
				<div className="form__group">
					<label htmlFor="password">password</label>
					<input
						placeholder="Min 8 characters"
						type="password"
						id="password"
						name="password"
						min={8}
						className="signin__input"
						onChange={handleChange}
					/>
					<div className="signin__extra">
						<Link
							to="/auth/password_recovery"
							className="btn btn-del form__link">
							forgot password?
						</Link>
					</div>
				</div>

				<div className="form__group form__submit__container">
					<button
						type="submit"
						value="Continue"
						className="form__submit btn btn-pri ">
						{loading ? <BtnLoader /> : "Continue"}
					</button>
				</div>
			</form>

			<div className="form__error__container">{errors ?? ""}</div>
		</motion.div>
	);
};

export default Signin;
