import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader, { getFileHeader } from "../Helpers/header";

import { handleErrors, handleSuccess } from "../Helpers/others";

import { getInstructors } from "./instructorSlice";
export const getCourses = createAsyncThunk(
	"courses/getCourses",
	async (sort, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		if (sort) {
			return axios
				.get(`${config.baseUrl}/admin/course/all?sort=${sort}`, options)
				.then((res) => res.data)
				.then(({ data }) => {
					dispatch(getInstructors());
					dispatch(getCategories());

					return data;
				})
				.catch((err) =>
					handleErrors({ err, msg: `failed to load courses` }, dispatch)
				);
		} else {
			return axios
				.get(`${config.baseUrl}/admin/course/all`, options)
				.then((res) => {
					return res.data;
				})
				.then(({ data }) => {
					dispatch(getInstructors());
					dispatch(getCategories());

					return data;
				})
				.catch((err) =>
					handleErrors({ err, msg: `failed to load courses` }, dispatch)
				);
		}
	}
);

export const getCategories = createAsyncThunk(
	"courses/getCategories",
	async (_, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/category/all`, options)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) =>
				handleErrors({ err, msg: `failed to get course categories` }, dispatch)
			);
	}
);
export const addCategory = createAsyncThunk(
	"courses/addCategory",
	async (details, { dispatch }) => {
		const formData = new FormData();

		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}
		// console.log("gotten url", formData.getAll());
		return axios
			.post(`${config.baseUrl}/admin/category/new`, formData, {
				headers: getFileHeader(),
			})
			.then((res) => {
				handleSuccess("successfully added new category", dispatch);
				console.log("res.data");
				return res.data;
			})
			.then(() => dispatch(getCategories()))
			.catch((err) => {
				console.log(err);
				handleErrors({ err, msg: `failed to add new category` }, dispatch);
			});
	}
);
export const updateCategory = createAsyncThunk(
	"courses/updateCategory",
	async (details, { dispatch }) => {
		const formData = new FormData();

		const entries = Object.entries(details);

		for (const [key, value] of entries) {
			if (key === "id") {
				continue;
			}
			formData.append(key, value);
		}
		// console.log("gotten url", formData.getAll());
		return axios
			.patch(`${config.baseUrl}/admin/category/${details?.id}`, formData, {
				headers: getFileHeader(),
			})
			.then((res) => {
				handleSuccess("successfully updated category", dispatch);
				console.log("res.data");
				return res.data;
			})
			.then(() => dispatch(getCategories()))
			.catch((err) => {
				console.log(err);
				handleErrors({ err, msg: `failed to update category` }, dispatch);
			});
	}
);
export const removeCategory = createAsyncThunk(
	"courses/removeCategory",
	async (id, { dispatch }) => {
		return axios
			.delete(
				`${config.baseUrl}/admin/category/${id}`,

				{
					headers: getHeader(),
				}
			)
			.then((res) => {
				handleSuccess("successfully deleted category", dispatch);
				console.log("res.data");
				return res.data;
			})
			.then(() => dispatch(getCategories()))
			.catch((err) => {
				console.log(err);
				handleErrors({ err, msg: `failed to delete category` }, dispatch);
			});
	}
);

export const setPrice = createAsyncThunk(
	"courses/setPrice",
	async (details, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(`${config.baseUrl}/admin/course/price`, details, options)
			.then((res) => {
				handleSuccess("successfully updated course price", dispatch);
				return res.data;
			})
			.then(() => dispatch(getCourses()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to update course price` }, dispatch)
			);
	}
);

export const setStatus = createAsyncThunk(
	"courses/setStatus",
	async ({ status, id }, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.patch(
				`${config.baseUrl}/admin/course/activate`,
				{
					courseId: String(id),
					active: Boolean(status),
				},
				options
			)
			.then((res) => {
				handleSuccess(
					`successfully ${status ? "enabled" : "disabled"} course `,
					dispatch
				);
				dispatch(getCourses());
				return res.data;
			})
			.catch((err) =>
				handleErrors(
					{
						err,
						msg: `failed to ${status ? "enable" : "disable"} course`,
					},
					dispatch
				)
			);
	}
);

export const courseSlice = createSlice({
	name: "courses",
	initialState: { all: [], status: null, categories: [] },

	extraReducers: {
		[getCourses.pending]: (state, action) => {
			state.status = "loading";
		},
		[getCourses.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getCourses.rejected]: (state, action) => {
			state.status = "failed";
		},
		[setPrice.pending]: (state, action) => {
			state.status = "loading";
		},
		[setPrice.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[setPrice.rejected]: (state, action) => {
			state.status = "failed";
		},
		[setStatus.pending]: (state, action) => {
			state.status = "loading";
		},
		[setStatus.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[setStatus.rejected]: (state, action) => {
			state.status = "failed";
		},
		[getCategories.pending]: (state, action) => {
			state.status = "loading";
		},
		[getCategories.fulfilled]: (state, { payload }) => {
			state.status = "success";
			state.categories = payload;
		},
		[getCategories.rejected]: (state, action) => {
			state.status = "failed";
		},
		[addCategory.pending]: (state, action) => {
			state.status = "loading";
		},
		[addCategory.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[addCategory.rejected]: (state, action) => {
			state.status = "failed";
		},

		[updateCategory.rejected]: (state, action) => {
			state.status = "failed";
		},
		[updateCategory.pending]: (state, action) => {
			state.status = "loading";
		},
		[updateCategory.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[removeCategory.rejected]: (state, action) => {
			state.status = "failed";
		},
		[removeCategory.pending]: (state, action) => {
			state.status = "loading";
		},
		[removeCategory.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
	},
});

// export const { getCourses, changeStatus, editPrice } = courseSlice.actions;
export default courseSlice.reducer;
