import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../Config.json";
import getHeader,{ getFileHeader } from "../Helpers/header";
import { handleSuccess, handleErrors } from "../Helpers/others";

import { getLanding } from "./landingSlice";

export const getTestimonials = createAsyncThunk(
	"testimonials/getTestimonials",
	async (_, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};

		return axios
			.get(`${config.baseUrl}/admin/testimonial/all`, options)
			.then((res) => res.data)
			.then(({ data }) => {
				dispatch(getLanding());

				return data;
			})
			.catch((err) =>
				handleErrors({ err, msg: `failed to load testimonials` }, dispatch)
			);
	}
);

export const setCover = createAsyncThunk(
	"testimonials/setCover",
	async (details, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};

		const formData = new FormData();
		formData.append("video", details);
		return axios
			.post(`${config.baseUrl}/admin/testimonial/cover`, formData, options)
			.then((res) => {
				handleSuccess("successfully updated cover video", dispatch);
				return res.data;
			})
			.then(() => dispatch(getTestimonials()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to update cover` }, dispatch)
			);
	}
);
export const editTestimonial = createAsyncThunk(
	"testimonials/editTestimonial",
	async (details, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};

		const formData = new FormData();
		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		return axios
			.patch(`${config.baseUrl}/admin/testimonial/update`, formData, options)
			.then((res) => {
				handleSuccess("successfully updated testimonial", dispatch);
				return res.data;
			})
			.then(() => dispatch(getTestimonials()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to update testimonial` }, dispatch)
			);
	}
);
export const addTestimonial = createAsyncThunk(
	"testimonials/addTestimonial",
	async (details, { dispatch }) => {
		const options = {
			headers: getFileHeader(),
		};

		const formData = new FormData();
		const entries = Object.entries(details);
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		return axios
			.post(`${config.baseUrl}/testimonial`, formData, options)
			.then((res) => {
				handleSuccess("successfully created testimonial", dispatch);
				return res.data;
			})
			.then(() => dispatch(getTestimonials()))

			.catch((err) =>
				handleErrors({ err, msg: `failed to create testimonial` }, dispatch)
			);
	}
);
export const deleteTestimonial = createAsyncThunk(
	"testimonials/deleteTestimonial",
	async (id, { dispatch }) => {
		const options = {
			headers: getHeader(),
		};

		return axios
			.delete(`${config.baseUrl}/admin/testimonial/delete/${id}`, options)
			.then((res) => {
				handleSuccess("successfully deleted testimonial", dispatch);
				return res.data;
			})
			.then(() => dispatch(getTestimonials()))
			.catch((err) =>
				handleErrors({ err, msg: `failed to delete testimonial` }, dispatch)
			);
	}
);

export const testimonialSlice = createSlice({
	name: "testimonials",
	initialState: { all: [], status: null },

	extraReducers: {
		[getTestimonials.pending]: (state, action) => {
			state.status = "loading";
		},
		[getTestimonials.fulfilled]: (state, { payload }) => {
			state.all = payload;
			state.status = "success";
		},
		[getTestimonials.rejected]: (state, action) => {
			state.status = "failed";
		},
		[setCover.pending]: (state, action) => {
			state.status = "loading";
		},
		[setCover.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[setCover.rejected]: (state, action) => {
			state.status = "failed";
		},
		[editTestimonial.pending]: (state, action) => {
			state.status = "loading";
		},
		[editTestimonial.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[editTestimonial.rejected]: (state, action) => {
			state.status = "failed";
		},
		[addTestimonial.pending]: (state, action) => {
			state.status = "loading";
		},
		[addTestimonial.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[addTestimonial.rejected]: (state, action) => {
			state.status = "failed";
		},
		[deleteTestimonial.pending]: (state, action) => {
			state.status = "loading";
		},
		[deleteTestimonial.fulfilled]: (state, { payload }) => {
			state.status = "success";
		},
		[deleteTestimonial.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

export default testimonialSlice.reducer;
