import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Btn } from "../../Components/UI/Buttons";
import { updateCoupon } from "../../Redux/couponSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { modalVariants } from "../../Helpers/animation";

const EditCouponModal = ({ coupon, closeModal }) => {
	const [details, setDetails] = useState();
	const handleChange = (e) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value.trim(),
		});
	};
	const { status } = useSelector((state) => state?.coupons);
	const dispatch = useDispatch();
	const editCoupon = () => {
		if (!details) {
			return;
		}

		dispatch(updateCoupon({ ...details, id: coupon["_id"] }));
	};

	return (
		<motion.div
			variants={modalVariants}
			key={"edit_course"}
			exit="exit"
			initial="start"
			animate="end"
			className="modal__container">
			<div className="modal ">
				<h2 className="modal__heading heading-sec heading">Edit coupon</h2>
				<div className="modal__content coupon__modal">
					<div className="modal__input__group__container">
						<div className="modal__input__group">
							<label htmlFor="name">Coupon name</label>
							<input
								type="text"
								onChange={handleChange}
								name="name"
								id="name"
								defaultValue={coupon?.name}
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="code">Coupon code</label>
							<input
								type="text"
								onChange={handleChange}
								name="code"
								id="code"
								defaultValue={coupon?.code}
							/>
						</div>
						<div className="modal__input__group">
							<label htmlFor="discount"> discount percentage</label>
							<input
								type="number"
								onChange={handleChange}
								name="discount"
								id="discount"
								defaultValue={coupon?.discount}
							/>
						</div>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn-cancel" onClick={closeModal}>
						Cancel
					</button>
					<Btn handleClick={editCoupon} status={status}>
						Submit
					</Btn>
				</div>
			</div>
		</motion.div>
	);
};

export default EditCouponModal;
