import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "./courseSlice";
import instructorReducer from "./instructorSlice";
import landingReducer from "./landingSlice";
import userReducer from "./userSlice";
import couponReducer from "./couponSlice";
import testimonialReducer from "./testimonialSlice";
import subscriberReducer from "./subscriberSlice";
import orientationReducer from "./orientationSlice";
import blogReducer from "./blogSlice";
import organizationReducer from "./organizationSlice";
import supportReducer from "./supportSlice";
import authReducer from "./authSlice";
import alertReducer from "./alertSlice";
import cohortReducer from "./cohortSlice";
import autoEnrollReducer from "./autoEnrollSlice"
export default configureStore({
	reducer: {
		auth: authReducer,
		courses: courseReducer,
		instructors: instructorReducer,
		users: userReducer,
		landing: landingReducer,
		coupons: couponReducer,
		testimonials: testimonialReducer,
		subscribers: subscriberReducer,
		orientation: orientationReducer,
		blog: blogReducer,
		organization: organizationReducer,
		support: supportReducer,
		alert: alertReducer,
		cohort: cohortReducer,
		autoEnroll:autoEnrollReducer,
	},
});
